import React, { useState } from "react";
import ProductSuccessfulLotti from "../Animation/Add-Product/ProductSuccessfulLotti";
import { Modal } from "react-bootstrap";
import "./AllModal.css";
import { Link } from "react-router-dom";

const ProductSuccessful = (props) => {
  return (
    <Modal
      className="lottimodal"
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <ProductSuccessfulLotti />
        <p className="text-center modal-text-class">
          Your Products Added Into Cart Successfully{" "}
        </p>
        <div className="text-center">
          <a href="./cart">
            <button className="view-cart-btn" onClick={props.onHide}>
              View Cart
            </button>
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProductSuccessful;
