import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./OurPartner.css";
import { Context } from "../../../utils/context";
import { OurPartners } from "../../../utils/apis/master/Master";
const OurPartner = () => {
  const { IMG_URL } = useContext(Context);
  const [data, setData] = useState();
  const getAllData = async () => {
    const res = await OurPartners();
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };
  useEffect(() => {
    getAllData();
  }, []);

  return (
    <section className="our-partner">
      <Container fluid>
        <Row>
          <Col lg={10} className="mx-auto">
            <div className="text-center pb-md-3 pb-4">
              <h3 className="hot-title">Our Partners</h3>
              <div className="title-border"></div>
            </div>
            <div className="main-div">
              <Row className="justify-content-center">
                {data?.map((item) => (
                  <Col
                    key={item.id}
                    xxl={2}
                    lg={2}
                    md={4}
                    sm={4}
                    xs={6}
                    className="my-auto"
                  >
                    <img
                      className="partners-img my-2"
                      src={IMG_URL + item?.image}
                      alt={item?.name}
                    />
                  </Col>
                ))}
                {/* <Col xxl={2} lg={2} md={4} sm={4} xs={6}><img className="partners-img"
                                    src={process.env.PUBLIC_URL + "/assets/Image/Homepage/partners/velocity.png"} alt="" /></Col>
                                <Col xxl={2} lg={2} md={4} sm={4} xs={6}><img className="partners-img"
                                    src={process.env.PUBLIC_URL + "/assets/Image/Homepage/partners/ideaa.png"} alt="" /></Col>
                                <Col xxl={2} lg={2} md={4} sm={4} xs={6}><img className="partners-img"
                                    src={process.env.PUBLIC_URL + "/assets/Image/Homepage/partners/treva.png"} alt="" /></Col>
                                <Col xxl={2} lg={2} md={4} sm={4} xs={6}><img className="partners-img"
                                    src={process.env.PUBLIC_URL + "/assets/Image/Homepage/partners/aven.png"} alt="" /></Col>
                                <Col xxl={2} lg={2} md={4} sm={4} xs={6}><img className="partners-img"
                                    src={process.env.PUBLIC_URL + "/assets/Image/Homepage/partners/hexlab.png"} alt="" /></Col>
                                <Col xxl={2} lg={2} md={4} sm={4} xs={6}><img className="partners-img"
                                    src={process.env.PUBLIC_URL + "/assets/Image/Homepage/partners/hexa.png"} alt="" /></Col> */}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default OurPartner;
