import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import "./Cart.css";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";

import Cookies from "js-cookie";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";

// import CouponModal from "../Common_modal/CouponModal/CouponModal"

import { Context } from "../../utils/context";

import { CartQuantity, getCart } from "../../utils/apis/addToCart/addToCart";

import { getCouponCode } from "../../utils/apis/orderProcess/orderProcess";
const Cart = () => {
  const [number, setNumber] = useState(1);
  const handleMinusClick = () => {
    if (number > 1) {
      setNumber(number - 1);
    }
  };

  const handlePlusClick = () => {
    setNumber(number + 1);
  };

  const tabledata = [
    {
      id: 1,
      image:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/products/cello-tape.png",
      itemName: "Cello Tape",
      variation: "Black",
      quantity: 1,
      tax: "12%",
      mrp: "$5",
      orderPrice: "$4",
      total: "$20",
    },
    {
      id: 2,
      image:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/products/marker-png.png",
      itemName: "Pen",
      variation: "White",
      quantity: 1,
      tax: "12%",
      mrp: "$5",
      orderPrice: "$4",
      total: "$20",
    },
  ];

  const navigate = useNavigate();
  const {
    IMG_URL,
    signin,
    triggerCartUpdate,
    changeQuantity,
    setChangeQuantity,
  } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const [amount, setAmount] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(0);
  var [totalPrice, setTotalPrice] = useState(0);
  var [totalTax, setTotalTax] = useState(0);
  var [grandTotal, setGrandTotal] = useState(0);
  var [totalDiscount, setTotalDiscount] = useState(0);
  var [message, setMessage] = useState("");

  // console.log(totalDiscount, "total discoyugfjhgjh");
  const [modalShow, setModalShow] = useState(false);

  const [couponStatus, setCouponStatus] = useState(false);

  const onSubmit = async (data) => {
    setError("code", "");

    const res = await getCouponCode(data?.code, amount);
    if (res?.success) {
      if (res?.couponCode) {
        setModalShow(true);
        setCoupon(res?.couponCode?.id);
        setCouponStatus(true);

        if (res?.couponCode?.type === "fixedamount") {
          setDiscount(res?.couponCode?.total_amount);
          setMessage(`Coupen Applied of Rs. ${res?.couponCode?.total_amount}`);
        } else {
          setDiscount(
            totalPrice - (totalPrice * res?.couponCode?.discount) / 100
          );
          setMessage(`${res?.couponCode?.discount} % Discount Applied`);
        }
        setTimeout(() => {
          setModalShow(false);
          setMessage("");
        }, 3000);
      } else {
        setDiscount(0);
        setCoupon("");
        setCouponStatus(false);
        setError("code", { message: "Coupen Invalid" });
      }
    } else {
      setDiscount(0);
      setCoupon("");
      setCouponStatus(false);
      setError("code", { message: res?.message });
    }
  };

  const [cart, setCart] = useState([]);
  const getCartQuantity = async (id, type) => {
    setDiscount(0);
    setCoupon();
    setCouponStatus(false);
    setError("code", "");

    await CartQuantity(id, type);
    triggerCartUpdate();
    if (Cookies.get("purevia_cart")) {
      if (JSON.parse(Cookies.get("purevia_cart")).length === 0) {
        navigate("/");
      }
      setChangeQuantity(!changeQuantity);
      setCart(JSON.parse(Cookies.get("purevia_cart")));
      withoutCart();
    }
    if (signin) {
      setChangeQuantity(!changeQuantity);

      await cartGet();
    }
  };

  const cartGet = async () => {
    const res = await getCart();
    if (res?.success) {
      if (res?.data.length === 0) {
        navigate("/");
      }
      setCart(res?.data);
    }
  };

  const withoutCart = async () => {
    if (Cookies.get("purevia_cart")) {
      if (JSON.parse(Cookies.get("purevia_cart")).length === 0) {
        navigate("/");
      }
      await setCart(JSON.parse(Cookies.get("purevia_cart")));
    }
  };

  console.log(changeQuantity, "llllllllllllll");
  useEffect(() => {
    if (signin) {
      cartGet();
    } else {
      withoutCart();
    }
  }, [Cookies.get("purevia_cart"), signin, changeQuantity]);

  // useEffect(() => {
  //   withoutCart();
  //   cartGet();
  // }, [changeQuantity]);
  console.log(
    Cookies.get("purevia_cart"),
    "mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm"
  );

  useEffect(() => {
    getCartQuantity();
  }, []);
  return (
    <section className="cart-main ">
      <Container>
        <Breadcrumb className="mt-4">
          <Breadcrumb.Item>
            <Link to="/" className="link-bredcumb">
              <FontAwesomeIcon icon={faHouse} />
            </Link>{" "}
            <FontAwesomeIcon
              icon="fa-solid fa-angle-right"
              className="link-bredcumb"
            />
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Cart</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col lg={9} xs={12} className="mt-3">
            <div className="table-responsive">
              <Table bordered className="text-center">
                <thead>
                  <tr>
                    <th className="table-title">S. No.</th>
                    <th className="table-title">Image</th>
                    <th className="table-title">Item Name</th>
                    {/* <th className="table-title">Variation</th> */}
                    <th className="table-title">Quantity</th>
                    {/* <th className="table-title">Tax</th> */}
                    <th className="table-title">MRP</th>
                    <th className="table-title">Order Price</th>
                    <th className="table-title">Tax</th>
                    <th className="table-title">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {cart.map(
                    (item, index) => (
                      (totalPrice += item?.product?.sale?.sale_price
                        ? item?.product?.sale?.sale_price * item?.quantity
                        : item?.product?.s_price * item?.quantity),
                      (totalTax +=
                        (parseInt(
                          item?.product?.product_detail?.tax_percentage?.name
                        ) *
                          (item?.product?.sale?.sale_price
                            ? item?.product?.sale?.sale_price * item?.quantity
                            : item?.product?.s_price * item?.quantity)) /
                        100),
                      (
                        // (totalDiscount =
                        //   item?.product?.mrp - item?.product?.s_price)
                        <tr key={item.id}>
                          <td className="table-data">{index + 1}</td>
                          <td>
                            <div className="table-img-div">
                              <img
                                src={IMG_URL + item.product?.image1}
                                className="table-img"
                                alt="..."
                              />
                            </div>
                          </td>
                          <td className="table-data">{item?.product?.name}</td>
                          {/* <td className="table-data">{item?.product?.name}</td> */}
                          <td>
                            <div className="d-flex add-cart-box mx-auto">
                              <button
                                variant="outline-secondary"
                                className="minus btn"
                                onClick={async () => {
                                  await getCartQuantity(item?.id, "minus");
                                  await cartGet();
                                }}
                              >
                                -
                              </button>
                              <div className="quantity">{item?.quantity}</div>
                              <button
                                variant="outline-secondary"
                                className="add btn"
                                onClick={async () => {
                                  await getCartQuantity(item?.id, "add");
                                  await cartGet();
                                }}
                              >
                                +
                              </button>
                            </div>
                          </td>
                          {/* <td className="table-data">
                                                ₹{item?.product?.s_price}</td> */}
                          <td className="table-data">{item?.product?.mrp}</td>
                          <td className="table-data">
                            ₹
                            {item?.product?.sale?.sale_price
                              ? item?.product?.sale?.sale_price
                              : item?.product?.s_price}
                          </td>
                          <td className="table-data">
                            {
                              item?.product?.product_detail?.tax_percentage
                                ?.name
                            }{" "}
                            %
                          </td>
                          <td className="table-data">
                            {" "}
                            ₹
                            {item?.product?.sale?.sale_price
                              ? (
                                  item?.product?.sale?.sale_price *
                                    item?.quantity +
                                  (parseInt(
                                    item?.product?.product_detail
                                      ?.tax_percentage?.name
                                  ) *
                                    (item?.product?.sale?.sale_price *
                                      item?.quantity)) /
                                    100
                                ).toFixed(2)
                              : (
                                  item?.product?.s_price * item?.quantity +
                                  (parseInt(
                                    item?.product?.product_detail
                                      ?.tax_percentage?.name
                                  ) *
                                    (item?.product?.s_price * item?.quantity)) /
                                    100
                                ).toFixed(2)}
                          </td>
                        </tr>
                      )
                    )
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
          <Col lg={3} xs={12} className="cart-second-sec ">
            {/* <Row className="border-bd">
              <Col md={7} xs={7}>
                <p className="total-p">Disco</p>
              </Col>
              <Col md={5} xs={5} className="text-center">
                <p className="total-p">₹ {discount}</p>
              </Col>
            </Row> */}

            <Row className="border-bd">
              <Col md={7} xs={7}>
                <p className="total-p">Total amount</p>
              </Col>
              <Col md={5} xs={5} className="text-center">
                <p className="total-p">₹{totalPrice}</p>
              </Col>
            </Row>
            {/* <Row className="border-bd">
                            <Col md={7} xs={7}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Control className="coupon-p" type="text" placeholder="Coupon code" />
                                </Form.Group>
                            </Col>
                            <Col md={5} xs={5} className="text-center">
                                <button className="btn apply-coupon-btn">Apply Coupon</button>
                            </Col>
                        </Row> */}
            <Row className="border-bd">
              <Col md={7} xs={7}>
                <p className="total-p">Tax</p>
              </Col>
              <Col md={5} xs={5} className="text-center">
                <p className="total-p">₹{totalTax?.toFixed(2)}</p>
              </Col>
            </Row>
            <Row className="border-bd">
              <Col md={7} xs={7}>
                <p className="total-p">Shipping</p>
              </Col>
              <Col md={5} xs={5} className="text-center">
                <p className="total-p">₹40</p>
              </Col>
            </Row>
            <Row className="border-bd">
              <Col md={7} xs={7}>
                <p className="grand-total-p">Grand Total</p>
              </Col>
              <Col md={5} xs={5} className="text-center">
                <p className="grand-total-p">₹{totalPrice + totalTax + 40}</p>
              </Col>
            </Row>
            <Link to="/checkout">
              <button className="btn checkout-btn">Checkout</button>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Cart;
