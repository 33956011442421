import React from "react";
import "./PrivacyPolicy.css";
const PrivacyPolicy = () => {
  return (
    <>
      <section className="privacy-policy">
        <div className="container">
          <div className="row">
            <div className="heading-holder">
              <h5>Privacy Policy</h5>
              <p>Purevianaturals.com by Rudransh naturals Privacy Policy</p>
              <h6>Introduction</h6>
              <p>
                Introduction Welcome to the Purevia Naturals by Rudransh
                naturals Privacy Policy. This document explains how we collect,
                use, and protect your personal data in accordance with
                applicable laws and regulations. By using our platform (the
                “Platform,” which includes our website and app), you agree to
                this policy and consent to the processing of your personal data
                as outlined herein.
              </p>
              <h6>Who We Are</h6>
              <p>
                <b>Purevia Naturals</b> , by Rudransh naturals is registered
                under the Companies Act, 2013, with our registered office
                located at E/B, 101, Rohan Heights, Near D.Y. Patil Hospital,
                Mahesh Nagar, Pimpri, Pune - 411018, Maharashtra, India. We
                specialize in the manufacturing, selling, marketing, and
                retailing of cosmetic and beauty products.
              </p>
              <h6>Our Roles</h6>
              <p>
                {" "}
                * Data Controller: We determine the purposes and means of
                processing your personal data.
              </p>
              <p>
                {" "}
                * Data Processor: We may process personal data on behalf of
                other data controllers.
              </p>
              <h6>Commitment to Data Protection</h6>
              <p>
                {" "}
                We are dedicated to safeguarding your privacy and continuously
                reviewing our data handling practices to ensure compliance with
                privacy laws.
              </p>
              <h6> Types of Information We Collect</h6>
              <h6>Information You Provide</h6>
              <p>
                {" "}
                * Contact Information: Name, email address, phone number,
                shipping address, date of birth.{" "}
              </p>
              <p>
                {" "}
                * Payment Information: Transaction details, bank information,
                card numbers.
              </p>
              <h6>Information We Collect Automatically</h6>
              <p>
                {" "}
                * Device Information: IP address, browser type, device
                identifiers.
              </p>{" "}
              <p>
                {" "}
                * Location Data: Information about your location when using our
                services.
              </p>
              <p> * Usage Data: Pages viewed, time spent on the Platform.</p>
              <h6>Information from Other Sources</h6>
              <p>
                {" "}
                We may obtain information about you from third parties to update
                our records and enhance your experience.
              </p>
              <h6> Categories of Personal Information </h6>
              <p> * Demographic &Identity Data</p>
              <p>* Online Identifiers & Technical Data</p>
              <p> * Communication Details</p>
              <p>* Usage Data</p>
              <h6>Consent</h6>
              <p>
                {" "}
                By using our Platform, you consent to the collection,
                processing, and sharing of your personal information as
                described in this policy.
              </p>
              <h6>Purpose of Data Collection</h6>
              <p>
                {" "}
                We use your personal information for the following purposes:
              </p>
              <p>
                *Order Management: To process transactions and deliver products.
              </p>
              <p>
                {" "}
                *Service Improvement: To enhance our offerings and resolve
                issues.
              </p>
              <p>
                {" "}
                * Personalization: To tailor your experience with
                recommendations and communications.
              </p>
              <p>
                {" "}
                * Marketing: To inform you of promotions and new products.{" "}
              </p>
              <h6> Cookies and Tracking Technologies</h6>
              <p>
                {" "}
                We use cookies and similar tracking technologies to improve your
                experience on our Platform. You can control cookie settings
                through your browser. For more details, please visit our cookie
                policy page.
              </p>
              <h6>Types of Cookies Used </h6>
              <p>
                {" "}
                * Strictly Necessary Cookies: Essential for the operation of our
                Platform.
              </p>{" "}
              <p>
                * <h6> Functional Cookies</h6>: Remember user preferences and
                settings.{" "}
              </p>
              <p>
                {" "}
                * <h6> Performance Cookies</h6>: Analyze how users interact with
                our Platform.
              </p>{" "}
              <h6>Data Sharing and Disclosure</h6>
              <p> We may share your personal information with:</p>
              <p>
                * <h6> Service Providers</h6>: Third parties assisting us with
                various functions.{" "}
              </p>
              <p>
                * <h6> Legal Authorities</h6>: When required by law or to
                protect our rights.
              </p>{" "}
              <p>
                * <h6> Business Partners</h6>: For marketing and promotional
                purposes, with your consent.
              </p>{" "}
              <h6>Cross-Border Data Transfer</h6>
              <p>
                Your personal information may be transferred to countries
                outside your home country. We will take appropriate measures to
                protect your data in such cases.
              </p>
              <h6>Data Security</h6>
              <p>
                We employ various security measures to protect your personal
                information from unauthorized access, loss, or alteration.
              </p>
              <h6>Data Retention</h6>
              <p>
                {" "}
                We will retain your personal information only as long as
                necessary for the purposes outlined in this policy and to comply
                with legal requirements.
              </p>
              <h6>Links to Other Websites</h6>
              <p>
                Our Platform may contain links to third-party websites. We are
                not responsible for their privacy practices, and we encourage
                you to review their policies.
              </p>
              <h6>Childrens Privacy</h6>
              <p>
                {" "}
                Our services are not intended for individuals under the age of
                18. We do not knowingly collect personal information from
                minors.
              </p>
              <h6>Your Rights</h6>
              <p>
                You have the right to access, correct, or delete your personal
                information. For any inquiries or concerns, please contact us at
                privacy@purevianaturals.com.
              </p>
              <h6>Customer Grievance Redressal Policy</h6>
              <p> We are committed to addressing customer</p>
              <h6>grievances effectively</h6>
              <p>If you have a complaint, please reach out via:</p>
              <h6> Email</h6>: info@purevianaturals.com  <h6>Phone</h6> Phone:
              7387541887 (Mon to Sun, 8 AM to 10 PM)
              <h6>Contact Us</h6>
              <p>
                For further queries regarding privacy, <h6>contact</h6>:
                Grievance Officer: Dr. Mamta Nakde Email:
                info@purevianaturals.com
              </p>
              <h6>Notification of Changes</h6>
              <p>
                {" "}
                We may update this Privacy Policy periodically. Any changes will
                be reflected on this page. Thank you for choosing Purevia
                Naturals. Your privacy is important to us.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
