import React from 'react'
import Aboutbanner from './AboutBanner/Aboutbanner'
import OurStory from './OurStory/OurStory'
import WhyChoose from './WhyChoose/WhyChoose'
import HappyClient from './HappyClient/HappyClient'
import Counters from './Counters/Counters'

function About() {
  return (
    <>
      <Aboutbanner />
      <OurStory />
      <WhyChoose />
      <HappyClient />
      <Counters />
    </>
  )
}

export default About