import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Set_New_Password from "../Set_New_Password/Set_New_Password";
import LogIn from "../LogIn";
import Check_Your_Email from "../Check_Your_Email/Check_Your_Email";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../../utils/common";
import {
  CheckExist,
  emailOtpGet,
} from "../../../utils/apis/myAccount/myAccount";
import Successfull_Modal from "../../Common-Component/Successfull_Modal/Successfull_Modal";
function Forget_Password() {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();
  const [modalShow, setModalShow] = useState(false);
  const [showSetNewPassword, setShowSetNewPassword] = useState(false);
  const [showCheckYourEmail, setShowCheckYourEmail] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  // const handleSubmit = () => {
  //   setShowCheckYourEmail(true);
  //   setTimeout(() => {
  //     setShowCheckYourEmail(false);
  //     setShowSetNewPassword(true);
  //   }, 3000); // Wait for 3 seconds before showing Set_New_Password
  // };

  const [email, setEmail] = useState("");

  const onSubmit = async (data) => {
    const res = await CheckExist(data);
    if (res?.success) {
      emailOtpGet(data);
      setEmail(data?.email);
      // setShowCheckYourEmail(true);
      setModalShow(true);
      setTimeout(() => {
        setShowCheckYourEmail(false);
        setShowSetNewPassword(true);
        setModalShow(false);
      }, 3000);
    } else {
      setError("email", { message: "User Not Found With This E-mail" });
      // setError('contact_no', { message: "" });
    }
  };

  return (
    <div className="LogIn">
      {!showSetNewPassword && !showLogin && !showCheckYourEmail && (
        <div className="row bg-color me-0 ms-0">
          <div className="col-md-7 p-0">
            <div className="row justify-content-center me-0 ms-0  ">
              <div className="col-md-6">
                <div className="back_to_span">
                  <Link to={"/"}>
                    <p>Back to Home</p>
                  </Link>
                </div>

                <div className="heading-holder">
                  <h4>Reset Your Password</h4>
                  <p>
                    You will receive an email from us requesting you to reset
                    your password.
                  </p>
                </div>
                <Form>
                  <div className="log_in_form">
                    <Form.Group className="mb-3" controlId="">
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="Enter Email"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: RegxExpression.email,
                            message: "Invalid email address",
                          },
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.email,
                          "is-valid": getValues("email"),
                        })}
                      />
                      {errors?.email && (
                        <div className="mt-md-0 mt-sm-3 mt-3">
                          <sup className="text-danger  ">
                            {errors?.email?.message}
                          </sup>
                        </div>
                      )}
                    </Form.Group>

                    <div className="btn-holder">
                      <Common_Button
                        className="SubmitBtn"
                        text={"Submit"}
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                      />
                    </div>

                    <div className="create_account">
                      {/* On click, set showLogin state to true */}
                      {/* <Link to={"/login"}> */}
                      <span
                        className="cancel-text"
                        onClick={() => setShowLogin(true)}
                      >
                        CANCEL
                      </span>
                      {/* </Link> */}
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <div className="col-md-5 p-0">
            <div className="img-section">
              <div className="img-holder">
                <img
                  className="log_in_img"
                  src={
                    process.env.PUBLIC_URL + "/assets/Image/myaccount/grppp.png"
                  }
                  alt="log_in_img"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {showCheckYourEmail && <Check_Your_Email />}
      {showSetNewPassword && <Set_New_Password email={email} />}
      {showLogin && <LogIn />}
      <Successfull_Modal
        message={`OTP sent to your Email ${email}`}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
}

export default Forget_Password;
