import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const AboutBanners = async () => {
  try {
    return await getData(`/without-login/about-us/about-banner`);
  } catch (error) {
    console.log(error);
  }
};

export const OurStories = async () => {
  try {
    return await getData(`/without-login/about-us/our-story`);
  } catch (error) {
    console.log(error);
  }
};

export const HappyCustomers = async (id) => {
  try {
    return await getData(`/without-login/about-us/happy-customers`);
  } catch (error) {
    console.log(error);
  }
};

export const WhyChooseUs = async (id) => {
  try {
    return await getData(`/without-login/about-us/why-choose-us`);
  } catch (error) {
    console.log(error);
  }
};
