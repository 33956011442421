import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function Check_Your_Email() {
  return (
    <>
      <div className="LogIn">
        <div className="row bg-color me-0 ms-0">
          <div className="col-md-5 p-0">
            <div className="row justify-content-center me-0 ms-0  ">
              <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10 col-sm-8">
                <div className="back_to_span">
                  <Link to={"/"}>
                    <p>Back to Home</p>
                  </Link>
                </div>
                <div className="heading-holder">
                  <h4>Check your email</h4>
                  <p>We send a password reset link to abc@gmail.com</p>
                </div>
                <div className="log_in_form">
                  <div className="btn-holder">
                    <Button className="submit_btn" type="button">
                      Open Gmail
                    </Button>
                  </div>
                  <div className="create_account">
                    <div className="login_text">
                      <p>
                        Didn't receive the email?
                        <span className="login_text">Click to resend</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 p-0">
            <div className="img-section">
              <div className="img-holder">
                {/* <img
                  className="log_in_img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Image/Login-Signup/loginimg.png"
                  }
                  alt="log_in_img"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Check_Your_Email;
