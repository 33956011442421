import React from "react";
import "./BlogInnerText.css";
import { Container } from "react-bootstrap";
function BlogInnerText({ blog }) {
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();
  return (
    <>
      <section className="innertxt-section">
        <Container>
          <div className="innertxt-main">
            <p className="txtdate">
              {new Date(blog?.createdAt).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short", // Use 'short' to display month name abbreviated like 'May'
                year: "numeric",
              })}
            </p>
            <h6 className="txthead">{blog?.name}</h6>
            <p className="txtcont mb-5">
              {htmlToReactParser.parse(blog?.long_description) || ""}
            </p>
          </div>
        </Container>
      </section>
    </>
  );
}

export default BlogInnerText;
