import React, { useState, useEffect, useContext, useRef } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import "./WishList.css";
import ProductSuccessful from "../AllModal/ProductSuccessful";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import {
  getWishlist,
  addWishlist,
} from "../../utils/apis/Wishlist/Wishlist.js";
import Heart_Component from "../Common-Component/Heart_Component/Heart_Component";
import SupermarketModal from "../Common-Component/Supermarket-modal/SupermarketModal";
import { AddToCart } from "../../utils/apis/addToCart/addToCart.js";
import { Context } from "../../utils/context";

const WishList = () => {
  const [isHeartRed, setIsHeartRed] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const toggleHeartColor = (index) => {
    const newHeartColors = [...isHeartRed];
    newHeartColors[index] = !newHeartColors[index];
    setIsHeartRed(newHeartColors);
  };

  const [modalShow, setModalShow] = React.useState(false);
  const [number, setNumber] = useState(1);
  const handleMinusClick = () => {
    if (number > 1) {
      setNumber(number - 1);
    }
  };

  const handlePlusClick = () => {
    setNumber(number + 1);
  };

  const [showButtons, setShowButtons] = useState(false);
  const [show, setShow] = useState(false);

  const wishlistItems = [
    {
      id: 1,
      offerPercentage: "-15%",
      productName: "Pen Name",
      productPrice: "$5",
      imageUrl:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/products/cello-tape.png",
      shoppingCartImageUrl:
        process.env.PUBLIC_URL + "/assets/Image/Icon/shopping-cart3.png",
    },
    {
      id: 2,
      offerPercentage: "-15%",
      productName: "Pen Name",
      productPrice: "$5",
      imageUrl:
        process.env.PUBLIC_URL +
        "/assets/Image/Homepage/products/marker-png.png",
      shoppingCartImageUrl:
        process.env.PUBLIC_URL + "/assets/Image/Icon/shopping-cart3.png",
    },
  ];

  const {
    cartCount,
    signin,
    setSignin,
    wishlistData,
    IMG_URL,
    triggerCartUpdate,
    wishlistCount,
    triggerWishlistUpdate,
    setAddCartModal,
    addCartModal,
  } = useContext(Context);

  const calculateOffPercentage = (mrp, S_Price) => {
    if (mrp > 0) {
      return ((mrp - S_Price) / mrp) * 100;
    }
    return 0;
  };

  // const [wishlist, setWishlist] = useState();
  // const getWishlisted = async () => {
  //   const res = await getWishlist();
  //   if (res?.success) {
  //     setWishlist(res?.data)
  //   }
  // }
  // useEffect(() => {
  //   getWishlisted()
  // }, [wishlistCount])

  const addToCart = async (product_id) => {
    const data = {
      product_id: product_id,
    };
    const res = await AddToCart(data);
    if (res?.success) {
      // getAllProduct();
    }
  };

  const navigate = useNavigate();
  const handleWishCart = async () => {
    await Promise.all(
      wishlistData?.map(async (value) => {
        console.log("prd", value?.product_id);
        await AddToCart({ product_id: value?.product_id });
        await addWishlist(value?.product_id);
        triggerCartUpdate();
      })
    );

    setModalShow(true);
    setTimeout(() => {
      setModalShow(false);
      navigate("/cart");
    }, 3000);
  };

  useEffect(() => {
    if (wishlistCount < 1) {
      navigate("/");
    }
  }, [wishlistCount]);

  return (
    <section className="wishlist">
      <Container>
        <Breadcrumb className="mt-4">
          <Breadcrumb.Item>
            <Link to="/">
              <FontAwesomeIcon
                icon="fa-solid fa-house"
                className="link-bredcumb"
              />
            </Link>{" "}
            <FontAwesomeIcon
              icon="fa-solid fa-angle-right"
              className="link-bredcumb"
            />
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Wishlist</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col md={12}>
            <Row>
              {wishlistData?.map((item) => (
                <Col
                  key={item.id}
                  xxl={3}
                  lg={4}
                  md={6}
                  sm={6}
                  xs={9}
                  className="mx-auto mt-4"
                >
                  <div className="card-section">
                    <div className="card">
                      <div className="card-set">
                        <Row>
                          <Col xs={6}>
                            {/* <p className="offer-card-p">
                              {calculateOffPercentage(
                                item?.product?.mrp,
                                item?.product?.s_price
                              ).toFixed(2)}
                              %
                            </p> */}
                          </Col>
                          <Col xs={6}>
                            <div className="wishlist-div11">
                              {/* <FontAwesomeIcon
                                onClick={() => toggleHeartColor(0)}
                                style={{
                                  color: isHeartRed[0] ? "red" : "#535353",
                                }}
                                icon={faHeart}
                                className="heart-iconss"
                              /> */}
                              <Heart_Component
                                product={item?.product}
                                // variant={selectedVariant}
                              />
                            </div>
                          </Col>
                        </Row>
                        <div className="card-body">
                          <div className="">
                            <img
                              className="product-img"
                              src={IMG_URL + item?.product?.image1}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="details">
                        <Row>
                          {/* <Col md={12} xs={12}>
                            <div className="d-flex">
                              <p className="product-name">
                                {item?.product?.name}
                              </p>
                              <p className="product-price">
                                {item?.product?.s_price}
                              </p>
                            </div>
                          </Col> */}
                          <Col md={12} xs={12}>
                            <div className="d-flex justify-content-between">
                              <div className="d-flex">
                                <p className="product-name">
                                  {item?.product?.name}
                                </p>
                                <p className="product-price">
                                  {item?.product?.s_price}
                                </p>
                              </div>
                              <div
                                id="first-div"
                                className="shopping-img-align onclickcart"
                              >
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/Image/Icon/shopping-cart3.png"
                                  }
                                  className="shopping-cart-img"
                                  alt="Shopping Cart"
                                  onClick={() => {
                                    addToCart(item?.product?.id);
                                    setAddCartModal(true);
                                  }}
                                />
                              </div>
                              {show &&
                                {
                                  /* <div
                                  id="second-div"
                                  className="shopping-img-align"
                                >
                                  <div className="d-flex add-cart-box">
                                    <button
                                      className="minus btn"
                                      onClick={() => handleMinusClick()}
                                    >
                                      -
                                    </button>
                                    <div className="quantity">{number}</div>
                                    <button
                                      className="add btn"
                                      onClick={() => handlePlusClick()}
                                    >
                                      +
                                    </button>
                                  </div>
                                </div> */
                                }}
                              <br />

                              {/* <div className="d-flex mt-1 shopping-align multicolors">
                                <Form.Check
                                  inline
                                  name="group1"
                                  type="radio"
                                  className="bt1"
                                />
                                <Form.Check
                                  inline
                                  name="group1"
                                  type="radio"
                                  className="bt2"
                                />
                                <Form.Check
                                  inline
                                  name="group1"
                                  type="radio"
                                  className="bt3"
                                />
                                <Form.Check
                                  inline
                                  name="group1"
                                  type="radio"
                                  className="bt4"
                                />
                                <Form.Check
                                  inline
                                  name="group1"
                                  type="radio"
                                  className="bt5"
                                />
                              </div> */}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}

              {/* Code for offer section */}
              <Col
                xxl={6}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                className="mb-4 mx-auto mt-4"
              >
                <div className="off-position">
                  <div className="offerimg-section">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Image/wishlist/offer.png"
                      }
                      className="offer-img"
                      alt="..."
                    />
                  </div>
                  <div className="img-text">
                    <h1 className="offer">20% off</h1>
                    <h6 className="code">Apply coupon code</h6>
                    <h2 className="lable-box">PUREVIA100</h2>
                  </div>
                </div>
              </Col>
            </Row>
            {/* Add all products to cart button */}
            <div className="add-cart-btn-align">
              <button className="btn add-cart-btn" onClick={handleWishCart}>
                Add all product into cart
              </button>
            </div>
            <ProductSuccessful
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WishList;
