import React, { useEffect, useContext } from "react";
import SupermarketCart from "../../Animation/supermarket-cart/SupermarketCart";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";

const SupermarketModal = (props) => {
  const { signin } = useContext(Context);

  useEffect(() => {
    if (props.show) {
      const timer = setTimeout(() => {
        props.onHide();
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [props.show]);

  return (
    <div className="Successfull_Modal_sec">
      <Modal
        className="Successfull_Modal"
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="lottie-holder">
            <SupermarketCart />
          </div>
          <p>Your Products Added Into Cart Successfully</p>
          <div className="text-center">
            {signin ? (
              <Link to="/cart" onClick={props.onHide}>
                <h4>View Cart</h4>
              </Link>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SupermarketModal;
