import React, { useState, useEffect, useContext } from "react";
import "./BecomeSeller.css";
import Common_Button from "../Common-Component/Common_Button/Common_Button";
import Successfull_Modal from "../Successfull_Modal/Successfull_Modal";
import { Col, Form, Row } from "react-bootstrap";
import { RegxExpression } from "../../utils/common";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import PasswordChecklist from "react-password-checklist";

import { Context } from "../../utils/context";
import Cookies from "js-cookie";
import { postData } from "../../utils/api";
function BecomeSeller() {
  const { signin, setSignin } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const onSubmit = async (data) => {
    const res = await postData("/seller/add", data);
    if (res?.success) {
    } else {
      if (res?.message?.both) {
        setError("email", { message: res?.message?.both });
        setError("contact_no", { message: "" });
      }
      if (res?.message?.email) {
        setError("email", { message: res?.message?.email });
      }
      if (res?.message?.contact_no) {
        setError("contact_no", { message: res?.message?.contact_no });
      }
    }
  };
  return (
    <>
      <section className="become-a-seller">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="image-seller text-center">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Image/Homepage/Banner/home-banner.png"
                  }
                  className="become-img"
                />
              </div>
            </div>
            <div className="col-xxl-7  col-xl-7 col-lg-8 col-md-10 mx-auto">
              <div className=" form-seller">
                <h2 className="mb-4">Seller Registration Form</h2>
                <Form>
                  <Row className="mb-3">
                    <Form.Group
                      as={Col}
                      md={6}
                      controlId="formGridname"
                      className="mb-3"
                    >
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="first_name"
                        placeholder="Enter First Name"
                        {...register("first_name", {
                          required: "First name required",
                          // pattern:
                          //   /^([A-Za-z_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.first_name,
                          "is-valid": getValues("first_name"),
                        })}
                        onKeyDown={(event) => {
                          if (!RegxExpression.name.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {errors?.first_name && (
                        <sup className="text-danger">
                          {errors?.first_name?.message}
                        </sup>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md={6}
                      controlId="formGridMobile"
                      className="mb-3"
                    >
                      <Form.Label>Mobile</Form.Label>
                      <Form.Control
                        className={classNames("", {
                          "is-invalid": errors?.contact_no,
                          "is-valid": getValues("contact_no"),
                        })}
                        type="text"
                        name="contact_no"
                        placeholder="Enter Mobile Number"
                        {...register("contact_no", {
                          required: "Field is required",
                          minLength: {
                            value: 10,
                            message: "Number should be at least 10 characters",
                          },
                        })}
                        onKeyDown={(event) => {
                          if (!RegxExpression.phone.test(event.key)) {
                            if (event.key !== "Backspace") {
                              event.preventDefault();
                            }
                          }
                        }}
                        maxLength={10}
                      />
                      {errors?.contact_no && (
                        <sup className="text-danger">
                          {errors?.contact_no?.message}
                        </sup>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md={6}
                      controlId="formGridEmail"
                      className="mb-3"
                    >
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="Enter Email"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: RegxExpression.email,
                            message: "Invalid email address",
                          },
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.email,
                          "is-valid": getValues("email"),
                        })}
                      />

                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                        }}
                      >
                        <span style={{ color: "green", fontSize: "1.2em" }}>
                          ✔
                        </span>
                      </div>
                      {errors?.email && (
                        <sup className="text-danger">
                          {errors?.email?.message}
                        </sup>
                      )}
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md={6}
                      controlId="formGridURL"
                      className="mb-3"
                    >
                      <Form.Label>Store URL</Form.Label>
                      <Form.Control type="text" placeholder="Enter Store URL" />
                    </Form.Group>
                  </Row>
                  <div className="text-center mt-5">
                    <Common_Button
                      className="btn-sub"
                      text=" Submit"
                      onClick={handleSubmit(onSubmit)}
                    />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BecomeSeller;
