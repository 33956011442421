import React, { useState, useEffect, useContext } from "react";
import "./Contact.css";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "react-bootstrap";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faPinterestP,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../utils/context";
import { postContactUs } from "../../utils/apis/common/Common";
import Successfull_Modal from "../Successfull_Modal/Successfull_Modal";
function Contact() {
  const [modalShow, setModalShow] = useState(false);
  const { getData, IMG_URL, Select2Data } = useContext(Context);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    watch,
    trigger,
    setValue,
  } = useForm();

  const handleKeyPressContact = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!/^\d$/.test(keyValue)) {
      event.preventDefault();
    }
  };

  const onSubmit = async (data) => {
    try {
      const res = await postContactUs(data);
      if (res?.success) {
        setModalShow(true);

        setTimeout(() => {
          reset();
          setModalShow(false);
        }, 2000);
      } else {
        console.error("Error fetching data:", res?.error);
      }
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };

  return (
    <>
      <section className="contactus-section">
        <Container>
          <div className="contactusmain">
            <Row>
              <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                <div className="gmaps">
                  <iframe
                    className="mapss"
                    src="https://www.google.com/maps/embed/v1/place?q=E/B,+101,+Rohan+Heights,+Near+D.Y.Patil+Hospital,+Mahesh+Nagar,+Pimpari,+Pune+-+411018,+Maharashtra,INDIA.&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
                    height="450"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                <div className="form-holder">
                  <div className="contact-headss">
                    <h5>Contact us</h5>
                  </div>
                  <div>
                    <Row>
                      <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className="contacts">
                          <div className="contact-icnss">
                            <FontAwesomeIcon
                              icon="fa-solid fa-phone"
                              className="iconss"
                            />
                          </div>
                          <div className="contacttxts">
                            <p>7387541887</p>
                          </div>
                        </div>
                      </Col>

                      <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className="contacts">
                          <div className="contact-icnss">
                            <FontAwesomeIcon
                              icon="fa-solid fa-location-dot"
                              className="iconss"
                            />
                          </div>
                          <div className="contacttxts">
                            <p>
                              E/B, 101, Rohan Heights, Near D.Y.Patil Hospital,
                              Mahesh Nagar, Pimpari, Pune - 411018,
                              Maharashtra,INDIA.
                            </p>
                          </div>
                        </div>
                      </Col>

                      <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className="contacts">
                          <div className="contact-icnss">
                            <FontAwesomeIcon
                              icon="fa-solid fa-envelope"
                              className="iconss"
                            />
                          </div>
                          <div className="contacttxts">
                            <p>info@purevianaturals.com</p>
                          </div>
                        </div>
                      </Col>

                      <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                        <div className="contacts">
                          <div className="contact-icnss">
                            <FontAwesomeIcon
                              icon="fa-solid fa-clock"
                              className="iconss"
                            />
                          </div>
                          <div className="contacttxts">
                            <p>Monday - Friday 9am to 5pm</p>
                            <p>Sunday - Closed</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="blogform">
                    <Form>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Enter Name"
                          {...register("name", {
                            required: "Name is required",
                            pattern: {
                              value: /^[A-Za-z ]+$/,
                              message: "Invalid",
                            },
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          onKeyDown={(event) => {
                            if (
                              !/[A-Za-z ]/.test(event.key) &&
                              event.key !== "Backspace"
                            ) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1 "
                      >
                        <Form.Control
                          type="text"
                          name="email"
                          placeholder="E-mail Address"
                          {...register("email", {
                            required: "Email Id required",
                            pattern: {
                              value:
                                /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                              message: "Email Id Invalid",
                            },
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.email,
                          })}
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="number"
                          name="contact_no"
                          placeholder="Contact No"
                          {...register("contact_no", {
                            required: "Contact No is required",
                            maxLength: {
                              value: 10,
                              message: "Contact No must be 10 digits",
                            },
                          })}
                          max={9999999999} // This will restrict the maximum value to 10 digits
                          min={1000000000} // This will restrict the minimum value to 10 digits
                          className={classNames("", {
                            "is-invalid": errors?.contact_no,
                          })}
                        />
                        {errors.contact_no && (
                          <div className="invalid-feedback">
                            {errors.contact_no.message}
                          </div>
                        )}
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="subject"
                          placeholder="Subject"
                          {...register("subject", {
                            required: "subject is required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.subject,
                          })}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          as="textarea"
                          name="message"
                          type="text"
                          rows={3}
                          placeholder="Enter Message"
                          {...register("message", {
                            required: "Message required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.message,
                          })}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <button
                          className="sentbtn"
                          onClick={handleSubmit(onSubmit)}
                        >
                          Send Message
                        </button>
                      </Form.Group>
                    </Form>
                  </div>
                  <div className="websites-div">
                    <Link
                      to={"https://www.facebook.com/pureviaindia"}
                      target="blank"
                    >
                      <div className="websites face">
                        <FontAwesomeIcon icon={faFacebookF} />
                      </div>
                    </Link>
                    <Link
                      to={"https://www.linkedin.com/company/pureviaindia"}
                      target="blank"
                    >
                      {" "}
                      <div className="websites linked">
                        <FontAwesomeIcon icon={faLinkedinIn} />
                      </div>
                    </Link>
                    {/* <Link to={"https://in.pinterest.com/"} target="blank"> <div className="websites pina">
                      <FontAwesomeIcon icon={faPinterestP} />
                    </div></Link> */}
                    <Link
                      to={"https://twitter.com/pureviaindia"}
                      target="blank"
                    >
                      {" "}
                      <div className="websites tiwt">
                        <FontAwesomeIcon icon={faTwitter} />
                      </div>
                    </Link>
                    <Link
                      to={"https://www.instagram.com/pureviaindia/"}
                      target="blank"
                    >
                      {" "}
                      <div className="websites insta">
                        <FontAwesomeIcon icon={faInstagram} />
                      </div>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <Successfull_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        namess="Message sent Successfully"
        // ninja="PUREVIA"
        // acont=" account"
      />
    </>
  );
}

export default Contact;
