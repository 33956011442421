import React, { useState, useEffect, useContext } from "react";
import "./OurStory.css";
import { Container } from "react-bootstrap";
import { Context } from "../../../utils/context";
import parse from "html-react-parser";
import { OurStories } from "../../../utils/apis/about/aboutApis";
function OurStory() {
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();
  const { IMG_URL } = useContext(Context);
  const [data, setData] = useState();
  const getAllData = async () => {
    const res = await OurStories();
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };
  useEffect(() => {
    getAllData();
  }, []);
  return (
    <>
      <section className="our-story">
        <Container>
          <div className="text-center">
            <h3 className="hot-title">Our Story</h3>
            <div className="title-border"></div>
            {/* <p className="sub-text">{data?.description}</p> */}
            <p>{htmlToReactParser.parse(data?.description) || ""}</p>
            {/* <div className="">
              <h5 className="h5-quote">
                “  {data?.name}  ”
              </h5>
            </div> */}
          </div>
        </Container>
      </section>
    </>
  );
}

export default OurStory;
