import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const postAddress = async (data) => {
  try {
    return await postData(`/useraddress/add`, data);
  } catch (error) {
    console.log(error);
  }
};

export const postEditAddress = async (data, id) => {
  try {
    return await postData(`/useraddress/edit/${id}`, data);
  } catch (error) {
    console.log(error);
  }
};

export const getAddress = async () => {
  try {
    return await getData(`/useraddress/show`);
  } catch (error) {
    console.log(error);
  }
};

export const getAddressData = async (id) => {
  try {
    return await getData(`/useraddress/show/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const Checkout = async (data) => {
  try {
    return await postData(`/useraddress/order`, data);
  } catch (error) {
    console.log(error);
  }
};

export const getCouponCode = async (code, amount) => {
  try {
    return await getData(
      `/coupenCode/coupon-code?code=${code}&amount=${amount}`
    );
  } catch (error) {
    console.log(error);
  }
};
