import React from "react";
import "./BestSeller.css";

const BestSeller = () => {
  return (
    <section class="offer">
      <div class="container-fluid background">
        <div class="container cont-pad">
          <div class="border-div">
            <div class="offer-text-div">
              <h1>Emphasis On Using
              High-Quality Herbs.</h1>
              <h4>Give yourself the gift of natural beauty and health & Indulge in the goodness of
              Natural Ayurvedic ingredients, based on scriptures.</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BestSeller;
