import React from "react";
import "./Successfull_Modal.css";
import { Button, Modal } from "react-bootstrap";
import Successfull_Lottie from "../Animation/Successfull_Lottie/Successfull_Lottie";

function Successfull_Modal(props) {
  const { show, onHide, namess,ninja,acont} = props;

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        className="Successfull_Modal"
        // {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="lottie-holder">
            {" "}
            <Successfull_Lottie />
          </div>

          <p>{namess} <span className="ninjas"> {ninja} </span> {acont}</p>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Successfull_Modal;
