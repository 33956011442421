import React, { useState } from "react";
import "./ForgotPassword.css";
import { Breadcrumb, Form, InputGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Successfull_Modal from "../../../Common-Component/Successfull_Modal/Successfull_Modal";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import PasswordChecklist from "react-password-checklist";

import { ChangePassword } from "../../../../utils/apis/myAccount/myAccount";

function ForgotPassword() {
  const [modalShow, setModalShow] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();

  const hideModalWithDelay = () => {
    setTimeout(() => {
      navigate("/my-account");
      setModalShow(false);
    }, 3000);
  };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const res = await ChangePassword(data);
    if (res?.success) {
      setModalShow(true);
      hideModalWithDelay();
    } else {
      if (res?.message?.current_password) {
        setError("current_password", {
          message: res?.message?.current_password,
        });
      }
      if (res?.message?.new_password) {
        setPasswordError(res?.message?.new_password);
      }
    }
  };

  const [showPasswordCurrent, setShowPasswordCurrent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordOne, setShowPasswordOne] = useState(false);

  const togglePasswordVisibility = (field) => {
    if (field === "current_password") {
      setShowPasswordCurrent(!showPasswordCurrent);
    } else if (field === "new_password") {
      setShowPassword(!showPassword);
    } else if (field === "re_password") {
      setShowPasswordOne(!showPasswordOne);
    }
  };

  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");

  return (
    <>
      <section className="changepassword-section my-account">
        <div className="container">
          <div className="heading_holder">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={"/my-account"}>My account</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <Link to={"/change-password"}>Change Password</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="back-text-holder">
            <Link to={"/my-account"}>
              <p>Back</p>
            </Link>
          </div>

          <div>
            <h2 className="title">Change Password</h2>
          </div>

          <div className="row">
            <div className=" col-lg-6 col-md-10 col-sm-10 col-12 mx-auto">
              <div className="passwordmain">
                <div className="pass-heading">
                  <h2>Change Your Password</h2>
                </div>
                <div className="formpart">
                  <Form>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type={showPasswordCurrent ? "text" : "password"}
                        placeholder="Enter Current Password*"
                        {...register("current_password")}
                      />
                      <InputGroup.Text id="basic-addon2">
                        <FontAwesomeIcon
                          icon={showPasswordCurrent ? faEye : faEyeSlash}
                          className="eye-icon"
                          onClick={() =>
                            togglePasswordVisibility("current_password")
                          }
                        />
                      </InputGroup.Text>
                    </InputGroup>

                    <InputGroup className="mb-3">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter Password*"
                        {...register("new_password", {
                          required: "Password is required",
                          minLength: {
                            value: 8,
                            message:
                              "Password must be at least 8 characters long",
                          },
                          pattern: {
                            value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                            message:
                              "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                          },
                        })}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <InputGroup.Text id="basic-addon2">
                        <FontAwesomeIcon
                          icon={showPassword ? faEye : faEyeSlash}
                          className="eye-icon"
                          onClick={() =>
                            togglePasswordVisibility("new_password")
                          }
                        />
                      </InputGroup.Text>
                    </InputGroup>

                    <InputGroup className="mb-3">
                      <Form.Control
                        type={showPasswordOne ? "text" : "password"}
                        placeholder="Re-Enter Password*"
                        {...register("re_password", {
                          required: "Re-Password is required",
                          validate: (value) =>
                            value === getValues("new_password") ||
                            "Passwords do not match",
                        })}
                        onChange={(e) => setPasswordAgain(e.target.value)}
                      />
                      <InputGroup.Text id="basic-addon2">
                        <FontAwesomeIcon
                          icon={showPasswordOne ? faEye : faEyeSlash}
                          className="eye-icon"
                          onClick={() =>
                            togglePasswordVisibility("re_password")
                          }
                        />
                      </InputGroup.Text>
                    </InputGroup>

                    {errors?.current_password && (
                      <sup className="text-danger">
                        {errors?.current_password?.message}
                      </sup>
                    )}
                    {errors?.re_password && (
                      <sup className="text-danger">
                        {errors?.re_password?.message}
                      </sup>
                    )}

                    <span style={{ color: "red" }}>{passwordError}</span>
                    {password && (
                      <PasswordChecklist
                        rules={[
                          "minLength",
                          "specialChar",
                          "number",
                          "capital",
                          "match",
                        ]}
                        minLength={8}
                        value={password}
                        valueAgain={passwordAgain}
                        onChange={(isValid) => {}}
                        style={{
                          fontSize: "10px",
                          padding: "4px",
                          margin: "0",
                        }}
                      />
                    )}
                  </Form>

                  <Common_Button
                    onClick={handleSubmit(onSubmit)}
                    text="Submit"
                    className="submitbtn"
                  />
                  <Link to={"/my-account"}>
                    <p className="cancel">Cancel</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Successfull_Modal
        message=" Your password has been changed successfully "
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default ForgotPassword;
