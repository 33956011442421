import React, { useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Successfull_Lottie from "../../../Animation/Successfull_Lottie/Successfull_Lottie";

function DeleteSuccessful(props) {
  useEffect(() => {
    const timer = setTimeout(() => {
      props.onHide();
    }, 3000);

    return () => clearTimeout(timer);
  }, [props]);

  return (
    <>
      <div>
        <Modal
          {...props}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="edit-modallll"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="lottie-holder">
              <Successfull_Lottie />
            </div>
            <p className="text-center">Address deleted successfully...!</p>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default DeleteSuccessful;
