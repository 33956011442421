import React, { useState, useEffect, useContext } from "react";
import "./FeaturedProduct.css";
import { Container, Row, Col } from "react-bootstrap";
import ProductSlider from "../Sale/ProductSlider/ProductSlider";
import { Context } from "../../../utils/context";
import { getAllFeaturedProducts } from "../../../utils/apis/Product/Product.js";

const FeaturedProduct = () => {
  const { IMG_URL } = useContext(Context);
  const [products, setProducts] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    getAllFeaturedProducts()
      .then((res) => {
        if (res?.success) {
          setProducts(res?.data);
          setDisplayedProducts(res?.data.slice(0, 8));
          setShowAll(res?.data.length > 8);
        }
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  }, []);

  const handleShowMoreClick = () => {
    setDisplayedProducts(products);
    setShowAll(true);
  };

  const handleShowLessClick = () => {
    setDisplayedProducts(products.slice(0, 8));
    setShowAll(false);
  };

  return (
    <section className="feature-section">
      <div className="text-center pb-md-3 pb-4 mt-5">
        <h3 className="hot-title">Our Products</h3>
        <div className="title-border"></div>
      </div>
      <Container>
        <div className="first-div">
          <Row className="mt-3">
            {displayedProducts.map((product) => (
              <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={12} key={product.id}>
                <ProductSlider product={product} IMG_URL={IMG_URL} />
              </Col>
            ))}
          </Row>
        </div>
        {products.length > 8 && (
          <div className="text-center mt-3">
            {!showAll ? (
              <button className="view-all-btn" onClick={handleShowMoreClick}>
                Show More
              </button>
            ) : (
              <button className="view-all-btn" onClick={handleShowLessClick}>
                Show Less
              </button>
            )}
          </div>
        )}
      </Container>
    </section>
  );
};

export default FeaturedProduct;
