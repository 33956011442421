import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Successfull_Modal from "../../Common-Component/Successfull_Modal/Successfull_Modal";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../../utils/common";
import {
  ChangePasswordWL,
  emailOtpVerify,
} from "../../../utils/apis/myAccount/myAccount";
import InputGroup from "react-bootstrap/InputGroup";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function Set_New_Password({ email }) {
  const [modalShow, setModalShow] = useState(false);
  const [errorOtp, setErrorOtp] = useState("");
  const [iconOne, setIconOne] = useState(faEyeSlash);
  const [iconTwo, setIconTwo] = useState(faEyeSlash);
  const [iconCurrent, setIconCurrent] = useState(faEyeSlash);

  const [otperror, setOtpError] = useState("");
  console.log(otperror, "oprthvfd");

  const hideModalWithDelay = () => {
    setTimeout(() => {
      // window.location.href = "/vendor-page"; // Change window location to root route ("/")
      setModalShow(false);
    }, 3000); // Change the delay time as needed (2000 milliseconds = 2 seconds)
  };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const onSubmit = async (data) => {
    data.email = email;

    setPassword(data?.new_password);
    const verified = await emailOtpVerify(data);
    if (verified?.success) {
      const res = await ChangePasswordWL(data);
      if (res?.success) {
        setModalShow(true);
        setTimeout(() => {
          window.location.reload();
          setModalShow(false);
        }, 3000);
        setErrorOtp("");
        setOtpError("");
      }
    } else {
      setOtpError(verified?.message);
    }
  };

  const [showPasswordCurrent, setShowPasswordCurrent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
      setIconOne(showPassword ? faEyeSlash : faEye);
    } else if (field === "reenterPassword") {
      setShowPasswordOne(!showPasswordOne);
      setIconTwo(showPasswordOne ? faEyeSlash : faEye);
    } else if (field === "current_password") {
      setShowPasswordCurrent(!showPasswordCurrent);
      setIconCurrent(showPasswordCurrent ? faEyeSlash : faEye);
    }
  };

  return (
    <>
      <div className="LogIn">
        <div className="row bg-color me-0 ms-0">
          <div className="col-md-7 p-0">
            <div className="row justify-content-center me-0 ms-0  ">
              <div className="col-lg-6 col-md-8">
                <div className="back_to_span">
                  <Link to={"/"}>
                    <p>Back to Home</p>
                  </Link>
                </div>

                <div className="heading-holder">
                  <h4>Set New Password</h4>
                  <p>
                    Your new password must be different to previously used
                    password.
                  </p>
                </div>

                <div className="log_in_form">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3">
                      <div className="password-field-holder">
                        <Form.Control
                          type="otp"
                          name="otp"
                          maxLength={4}
                          placeholder="OTP"
                          {...register("otp", {
                            required: "OTP is required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.otp,
                            "is-valid": getValues("otp"),
                          })}
                        />
                      </div>
                      {errors?.otp && (
                        <div className="mt-md-0 mt-sm-3 mt-3">
                          <sup className="text-danger  ">
                            {errors?.otp?.message}
                          </sup>
                        </div>
                      )}
                    </Form.Group>

                    {/* {otperror} */}
                    <span style={{ color: "red" }}>{otperror}</span>

                    <Form.Group className="mb-3">
                      <div className="password-field-holder">
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          name="new_password"
                          placeholder="Enter New Password"
                          {...register("new_password", {
                            required: "New Password is required",
                            minLength: {
                              value: 8,
                              message:
                                "Password must be at least 8 characters long",
                            },
                            pattern: {
                              value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                              message:
                                "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                            },
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.new_password,
                            "is-valid": getValues("new_password"),
                          })}
                        />
                        <FontAwesomeIcon
                          icon={iconOne}
                          className="eye-icon"
                          onClick={() => togglePasswordVisibility("password")}
                        />
                      </div>
                      {errors?.new_password && (
                        <div className="mt-md-0 mt-sm-3 mt-3">
                          <sup className="text-danger  ">
                            {errors?.new_password?.message}
                          </sup>
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <div className="password-field-holder">
                        <Form.Control
                          type={showPasswordOne ? "text" : "password"}
                          name="confirm_password" // Ensure this name is unique
                          placeholder="Confirm New Password"
                          {...register("confirm_password", {
                            required: "Confirm Password is required",
                            validate: (value) =>
                              value === getValues("new_password") ||
                              "Passwords do not match",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.confirm_password,
                            "is-valid": getValues("confirm_password"),
                          })}
                        />
                        <FontAwesomeIcon
                          icon={iconTwo}
                          className="eye-icon"
                          onClick={() =>
                            togglePasswordVisibility("reenterPassword")
                          }
                        />
                      </div>
                      {errors?.confirm_password && (
                        <div className="mt-md-0 mt-sm-3 mt-3">
                          <sup className="text-danger  ">
                            {errors?.confirm_password?.message}
                          </sup>
                        </div>
                      )}
                    </Form.Group>

                    <div className="btn-holder mb-5">
                      <Common_Button
                        className="SubmitBtn"
                        text=" Reset Password"
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                      />
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5 p-0">
            <div className="img-section">
              <div className="img-holder">
                <img
                  className="log_in_img"
                  src={
                    process.env.PUBLIC_URL + "/assets/Image/myaccount/grppp.png"
                  }
                  alt="log_in_img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Successfull_Modal
        message="Password Updated Successfully"
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Set_New_Password;
