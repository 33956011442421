import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const orderGet = async () => {
  try {
    const res = await getData(`/useraddress/orderget`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const returnOrderGet = async () => {
  try {
    const res = await getData(`/useraddress/order-return`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const OrderCancel = async (data) => {
  try {
    const res = await postData(`/useraddress/order-cancel`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const orderReturn = async (data) => {
  try {
    const res = await postData(`/useraddress/order-return`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const singleOrderGet = async (id) => {
  try {
    const res = await getData(`/useraddress/orderget/${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const UserGet = async () => {
  try {
    const res = await getData(`/admin/usersingleget`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const UserEdit = async (data) => {
  try {
    const res = await postData(`/admin/userEdit`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const ChangePassword = async (data) => {
  try {
    const res = await postData(`/admin/change-password`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const CheckExist = async (data) => {
  console.log("dataEmail :--", data);
  try {
    const res = await postData(`/admin/check-exist`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const emailOtpGet = async (data) => {
  console.log("e,mail", data);
  try {
    const res = await postData(
      `/without-login/verify-otp/email-otp-send`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const emailOtpVerify = async (data) => {
  try {
    const res = await postData(
      `/without-login/verify-otp/email-verify-otp`,
      data
    );

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const ChangePasswordWL = async (data) => {
  try {
    const res = await postData(`/admin/change-password-wl`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};
