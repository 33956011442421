import React, { useContext, useEffect, useState } from "react";
import "./ProdcutDescription.css";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Rating } from "react-simple-star-rating";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";

import {
  allProductReviewGet,
  getProductDetail,
  productReviewGet,
  productReviewPost,
} from "../../../utils/apis/Product/Product";
import { Context } from "../../../utils/context";
import { useNavigate } from "react-router-dom";

const ProdcutDescription = ({ product }) => {
  const [selectedRating, setSelectedRating] = useState(0);
  const [ratings, setRatings] = useState([0, 0, 0, 0, 0]); // Initially all stars are empty
  const [modalShow, setModalShow] = React.useState(false);
  const [showError, setShowError] = useState("");

  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const navigate = useNavigate();
  const handleStarClick = (starIndex) => {
    const newRatings = [...ratings];
    for (let i = 0; i < newRatings.length; i++) {
      newRatings[i] = i < starIndex ? 1 : 0; // Set to 1 for filled star, 0 for empty star
    }
    setSelectedRating(starIndex);
    setRatings(newRatings);
    setValue("ratings", starIndex); // Update form value if needed
  };

  const renderStars = () => {
    return ratings.map((rating, index) => (
      <FontAwesomeIcon
        key={index}
        icon={rating === 1 ? solidStar : regularStar}
        className="star-icon me-1"
        onClick={() => handleStarClick(index + 1)}
      />
    ));
  };

  const { IMG_URL, signin } = useContext(Context);
  const [reviews, setReviews] = useState([]);
  const getProductReview = async () => {
    const res = await getProductDetail(product?.id);
    if (res?.success) {
      setReviews(res?.data?.product_reviews);
    }
  };

  const [userreview, setUserReviews] = useState(false);
  const getUserReview = async () => {
    const res = await productReviewGet(product?.id);
    setUserReviews(res?.data?.hasReviewed);
  };

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      ratings: 0,
      review_message: "",
    },
  });

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleGoTOLoginpage = () => {
    alert();
    navigate("/login");
  };

  const handleRemoveImage = (index) => {
    const filteredFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(filteredFiles);
  };

  const [selectedFiles, setSelectedFiles] = useState([]);
  const onSubmit = async (data) => {
    const sendData = new FormData();
    if (data?.ratings > 0) {
      sendData.append("ratings", data?.ratings);
      sendData.append("review_message", data?.review_message);
      sendData.append("product_id", product?.id);

      if (selectedFiles.length > 0) {
        selectedFiles?.map((image, index) => {
          sendData.append(`images[${index}]`, image);
        });
      }
      const res = await productReviewPost(sendData);
      if (res?.success) {
        getProductReview();
        getUserReview();
        setRatings([0, 0, 0, 0, 0]);
        reset();
        setValue("review_message", "");
        setSelectedFiles([]);
        setShowError("");
        setModalShow(true);
        setTimeout(() => {
          setModalShow(false);
        }, 3000);
      } else {
        setShowError("You have Already reviewed");
      }
    } else {
      setShowError("Please Select Reating");
    }
  };

  useEffect(() => {
    getProductReview();
    getUserReview();
  }, [product]);

  return (
    <section className="prodcut-description mt-5">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Nav variant="pills" className="flex-row">
          <Nav.Item>
            <Nav.Link eventKey="first">Product Description</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="second">Product Reviews</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="first">
            <p className="prodct-desc-text">
              {htmlToReactParser.parse(product?.short_description)}
            </p>
            <div className="blk-border"></div>
          </Tab.Pane>

          {/* {userreview ? (
            ""
          ) : ( */}
            <Tab.Pane eventKey="second">
              <div className="tab3-content">
                <div className="add-review">
                  <div className="">
                    {!signin ? (
                      <Link to="/login" className="rate-btn">
                        <button className="rate-btn">Rate & Review</button>
                      </Link>
                    ) : (
                      <div className="rate-btn">Rate & Review</div>
                    )}
                  </div>
                  <div className="border-bottom-rate"></div>
                  {signin && (
                    <div className="review-form-sec">
                      <p className="rating-text-p">
                        Rating ({selectedRating}/5)
                      </p>
                      <div className="d-flex">{renderStars()}</div>
                      <span style={{ color: "red" }}>{showError}</span>
                      <div className="pt-3">
                        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                        <div className="">
                          <label className="review-form-text">
                            Add Images / Videos
                          </label>
                          <div className="d-flex mb-3">
                            <div className="add-div">
                              <Form.Label className="plus-btn me-0 mb-0">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/Image/Icon/add.png"
                                  }
                                  className="add"
                                  alt="Add Icon"
                                />
                                <Form.Control
                                  type="file"
                                  onChange={handleFileChange}
                                  multiple
                                  accept="image/*,video/*"
                                  style={{ display: "none" }} // Hide the file input element
                                />
                              </Form.Label>
                            </div>
                            <div className="add-img-div"></div>

                            <div className="add-img-div">
                              <div className="image-holder">
                                {selectedFiles.length > 0 &&
                                  selectedFiles.map((file, index) => (
                                    <div
                                      className="file-img-holder"
                                      key={index}
                                    >
                                      <object
                                        className="img-logo"
                                        data={URL.createObjectURL(file)}
                                        type={file.type}
                                        width="59"
                                        height="58"
                                      />
                                      <div
                                        className="cross-overlay"
                                        onClick={() => handleRemoveImage(index)}
                                      >
                                        <img
                                          className="img-cross"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/Image/Icon/cross-icon.png"
                                          }
                                          alt={`Cross Icon ${index + 1}`}
                                        />
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>Write a Product review?</Form.Label>
                          <Form.Control
                            as="textarea"
                            name="review_message"
                            type="text"
                            rows={3}
                            placeholder="Enter Message"
                            {...register("review_message", {
                              required: "Message required",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.review_message,
                            })}
                          />
                        </Form.Group>
                        <div className="button-holder text-md-end text-center">
                          <Common_Button
                            text=" Cancel"
                            onClick={() => {
                              setRatings([0, 0, 0, 0, 0]);
                              reset();
                              setSelectedFiles([]);
                            }}
                            className="cancel-btn me-2"
                          />
                          <Common_Button
                            onClick={handleSubmit(onSubmit)}
                            text="post"
                            className="post-btn me-2"
                          />
                        </div>
                        {/* </form> */}
                      </div>
                    </div>
                  )}
                </div>
                <div className="row list-wrapper mt-3">
                  {reviews?.map((review, index) => (
                    <Col
                      key={index}
                      lg={6}
                      md={12}
                      className="mb-3 review-box "
                    >
                      <div className="card card-div content list-item">
                        <Row>
                          <Col xxl={10} lg={9} md={9} sm={9} xs={9}>
                            <div className="card-div-content pt-2">
                              <h5 className="name">
                                {review?.user?.first_name}
                              </h5>
                              <p className="review">{review.review_message}</p>
                              <div>
                                {review?.product_reviews_images?.map(
                                  (image, imgIndex) => (
                                    <object
                                      key={imgIndex}
                                      className="img-card"
                                      data={IMG_URL + image?.image}
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                      }}
                                    />
                                  )
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col
                            xxl={2}
                            lg={3}
                            md={3}
                            sm={3}
                            xs={3}
                            className=" text-end d-flex justify-content-end"
                          >
                            <div className="date-align p-1">
                              <p>
                                {new Date(review?.createdAt).toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "long",
                                    day: "2-digit",
                                  }
                                )}
                              </p>
                              <div className="d-flex mx-auto star-div">
                                {[...Array(parseInt(review?.ratings))].map(
                                  (_, i) => (
                                    <FontAwesomeIcon
                                      key={i}
                                      icon={solidStar}
                                      className="review-box-img"
                                    />
                                  )
                                )}
                                {[...Array(5 - parseInt(review?.ratings))].map(
                                  (_, i) => (
                                    <FontAwesomeIcon
                                      key={i}
                                      icon={regularStar}
                                      className="review-box-img"
                                    />
                                  )
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  ))}
                </div>

                {reviews.length > 4 ? (
                  <div className="text-center load-more">
                    <button className="loadMore btn ">Load More</button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Tab.Pane>
          {/* )} */}
        </Tab.Content>
      </Tab.Container>
    </section>
  );
};

export default ProdcutDescription;
