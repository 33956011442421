import React, { useState, useEffect, useContext } from "react";
import { Breadcrumb, Container } from 'react-bootstrap'
import './BlogInnerBanner.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Context } from "../../../../utils/context";
const BlogInnerBanner = ({ blog }) => {

  const { IMG_URL } = useContext(Context);
  return (
    <section className='binnerbanner-section'>
      <Container>
        <Breadcrumb className="mt-5">
          <Breadcrumb.Item>
            <Link to="/blog" className="link-bredcumb">
              Blog
            </Link>{" "}
            <FontAwesomeIcon icon="fa-solid fa-angle-right" className="link-bredcumb" />
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{blog?.name}</Breadcrumb.Item>
        </Breadcrumb>
        <div className='bloginner'>
          <img
            className='blogin-img'
            // src={process.env.PUBLIC_URL + '/assets/Image/blog/bloginner.png'} 
            src={IMG_URL + blog?.big_image}
            alt="Logo" />
        </div>
      </Container>
    </section>
  )
}

export default BlogInnerBanner