import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import DeleteSuccessful from "../EditModal/DeleteSuccessful/DeleteSuccessful";
import { Context } from "../../../utils/context";
import { deleteData } from "../../../utils/api";

const Want_to_delete_modal = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const navigate = useNavigate();

  const {
    cartCount,
    signin,
    setSignin,
    wishlistData,
    IMG_URL,
    triggerCartUpdate,
    wishlistCount,
    triggerWishlistUpdate,
    setAddCartModal,
    addCartModal,
    getData,
  } = useContext(Context);

  const handleLogout = async () => {
    await Cookies.remove("purevia_security", { path: "/" });
    await setSignin(false);

    await navigate("/");
    props.onHide();
    window.location.reload();
  };

  const handleYesClick = async () => {
    setModalShow(true);
    const response = await deleteData(
      `/useraddress/address-delete/${props.delete_id}`
    );
    props.onHide();
    props.addressGet();
  };

  return (
    <>
      <Modal
        className="lottimodal"
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p className="text-center modal-text-class">
            Are you sure to Delete{" "}
            <span className="netpurti-btn">Address </span>
          </p>
          <div className="text-center">
            <button onClick={props.onHide} className="cancel-btn me-3">
              No
            </button>
            <button onClick={handleYesClick} className="Logout-btn">
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <DeleteSuccessful show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default Want_to_delete_modal;
