import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function HideNavbar({ children }) {
  const location = useLocation();
  const [shownavbar, setShownavbar] = useState(false);
  useEffect(() => {
if(location.pathname==='/login'){
    setShownavbar(false);
}
else{
    setShownavbar(true); 
}

  }, [location]);

  return <>{shownavbar && children}</>;
}

export default HideNavbar;
