import React, { useState, useEffect, useContext } from "react";
import "./HappyClient.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Rating from "react-rating-stars-component";
import { Container } from "react-bootstrap";
import { Context } from "../../../utils/context";
import parse from "html-react-parser";
import { HappyCustomers } from "../../../utils/apis/about/aboutApis";
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination } from 'swiper/modules';

function HappyClient() {
  const [rating, setRating] = useState(0);
  

  const handleRating = (rate) => {
    setRating(rate);
  };

  const onPointerEnter = () => console.log("Enter");
  const onPointerLeave = () => console.log("Leave");
  const onPointerMove = (value, index) => console.log(value, index);

  const client = [
    {
      imgs: process.env.PUBLIC_URL + "/assets/Image/blog/recent5.png",
      name: "Rose Merry",
      reviews:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      imgs: process.env.PUBLIC_URL + "/assets/Image/blog/recent5.png",
      name: "Kristina B",
      reviews:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      imgs: process.env.PUBLIC_URL + "/assets/Image/blog/recent5.png",
      name: "Rose Merry",
      reviews:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
  ];

  const { IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const getAllData = async () => {
    const res = await HappyCustomers();
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };
  useEffect(() => {
    getAllData();
  }, []);

  return (
    <>
      <section class="happy-clients-section">
        <Container>
          <div class="row">
            <div class="col-lg-12 mx-auto">
              <div class="text-center">
                <h3 class="hot-title">Happy Customers</h3>
                <div class="title-border"></div>
              </div>
              <div class="row mt-5">
                  <Swiper
              slidesPerView={3}
              spaceBetween={10}
              pagination={{ clickable: true }}
              modules={[Pagination]}
              className="happy-clients_mySwiper"
              breakpoints={{
                // when window width is >= 640px
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                // when window width is >= 768px
                321: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },

                485: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },

                768: {
                  slidesPerView: 2,
                  spaceBetween: 8,
                },

                992: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                // when window width is >= 1024px
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
              }}
            >
                {data?.map((item) => (
              <SwiperSlide>
                    <div class="client-div">
                      <div className="clints">
                        <div>
                          <img
                            class="client-img"
                            src={IMG_URL + item?.image}
                            alt=""
                          />
                        </div>
                        <div>
                          <h5 class="client-name">{item?.name}</h5>
                        </div>
                      </div>
                      <div class="mt-3">
                        <p class="client-review">{item?.description}</p>
                        <div class="d-flex star-holder">
                          <Rating
                            className="rating-star"
                            value={item?.star} // Use the value from your data
                            edit={false} // Disable editing
                          />
                        </div>
                      </div>
                    </div>
                    </SwiperSlide>
                ))}
                </Swiper>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default HappyClient;
