import React, { useState, useEffect, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-regular-svg-icons";

import { Coupons } from "../../../utils/apis/master/Master";
import { Context } from "../../../utils/context";
import "./coupanModal.css";

const CoupanModal = ({ show, handleClose, setCopyCode }) => {
  const { IMG_URL } = useContext(Context);
  const [coupons, setCoupons] = useState([]);
  const [copied, setCopied] = useState(false);

  console.log(copied, "copied");

  useEffect(() => {
    const fetchCoupons = async () => {
      const res = await Coupons();
      if (res?.success) {
        setCoupons(res.data);
      }
    };
    fetchCoupons();
  }, []);

  const handleCopy = (code) => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopyCode(code);
          setCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      centered
      className="copounmodall"
    >
      <Modal.Header closeButton>
        <Modal.Title>All Coupons</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          {coupons.map((item, index) => (
            <div
              onClick={() => handleCopy(item.code)}
              key={index}
              className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3"
            >
              <div className="card coupancard">
                <div className="card-body">
                  <div className="card-details">
                    <h5 className="coupontitle">{item.name}</h5>
                  </div>
                  {item?.type === "percentage" ? (
                    <p className="couptext">
                      <b>{item.discount}%</b>
                      <br /> get {item.discount}% off on the minimum order of{" "}
                      {item?.minimum_order}
                    </p>
                  ) : (
                    <p className="couptext">
                      <b>₹. {item.discount}</b>
                      <br /> get ₹. {item.discount} off on the minimum order of{" "}
                      {item?.minimum_order}
                    </p>
                  )}
                  <div className="border-card"></div>
                  <div className="main-images">
                    <div className="image">
                      <img
                        className="card-img"
                        src={`${IMG_URL}${item?.image}`}
                        alt="Logo"
                      />
                    </div>
                  </div>
                  <div className="coupancode">
                    <p className="codetxt">
                      Code: <span className="me-2">{item.code}</span>
                      <span>
                        {/* <FontAwesomeIcon
                          icon={faCopy}
                          onClick={() => handleCopy(item.code)}
                          style={{ cursor: "pointer" }}
                        /> */}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {copied && (
          <span style={{ marginLeft: "5px", alignSelf: "center" }}>Copied</span>
        )}
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CoupanModal;
