import React from 'react'
import HomeBanner from './HomeBanner/HomeBanner'
import Sale from './Sale/Sale'
import BestSeller from './BestSeller/BestSeller'
import FeaturedProduct from './FeaturedProduct/FeaturedProduct'
import OurPartner from './OurPartner/OurPartner'

const HomePage = () => {
    return (
        <>
            <HomeBanner />
            <Sale />
            <BestSeller />
            <FeaturedProduct />
            <OurPartner />
        </>
    )
}

export default HomePage