import React, { useState, useContext, useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./EditModal.css";
import EditSuccessFul from "./EditSuccessFul/EditSuccessFul";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import Select from "react-select";
import { Context } from "../../../utils/context";
import Cookies from "js-cookie";
import {
  Country,
  State,
  City,
  Pincode,
  Pincodes,
} from "../../../utils/apis/master/Master";
import { Errors } from "../../../utils/errors";
import { RegxExpression } from "../../../utils/common";
import {
  getAddress,
  getAddressDat,
  getAddressData,
  postAddress,
  postEditAddress,
} from "../../../utils/apis/orderProcess/orderProcess";
import { deleteData, getData } from "../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EditModal = (props) => {
  const options = [
    { value: "Home", label: "Home" },
    { value: "Work", label: "Work" },
    { value: "Office", label: "Office" },
  ];

  console.log(props.address_id, "gdfjsgdfjgjfsjdhg");
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
    setValue,
  } = useForm();

  const [modalShow, setModalShow] = React.useState(false);
  const [singleAddress, setSingleAddress] = useState("false");

  console.log(singleAddress, "jhhgfjgh");

  const onSubmit = async (data) => {
    const sendData = {
      name: data?.name,
      address_type: data?.address_type?.value,
      mobile_number: data?.mobile_number,
      flat_home_bldg: data?.flat_home_bldg,
      col_land_strt: data?.col_land_strt,
      country_id: data?.country_id?.value,
      state_id: data?.state_id?.value,
      city_id: data?.city_id?.value,
      pincode_id: data?.pincode_id?.value,
    };
    const res = await postEditAddress(sendData, props.address_id);
    if (res?.success) {
      props.addressGet();
      reset();
      setModalShow(true);
      setTimeout(() => {
        props.onHide();
        setModalShow(false);
        window.location.reload();
      }, 3000);
    }
  };

  const getSingleAddress = async () => {
    const res = await getAddressData(props.address_id);
    if (res?.success) {
      setSingleAddress(res?.data);
      setValue("name", res?.data?.name);
      setValue("address_type", res?.data?.address_type);
      setValue("flat_home_bldg", res?.data?.flat_home_bldg);
      setValue("col_land_strt", res?.data?.col_land_strt);
      setValue("mobile_number", res?.data?.mobile_number);

      setValue("country_id", {
        value: res?.data?.country_id?.id,
        label: res?.data?.country_id?.label,
      });
      setValue("state_id", {
        value: res?.data?.state_id?.id,
        label: res?.data?.state_id?.label,
      });
      setValue("city_id", {
        value: res?.data?.city_id?.id,
        label: res?.data?.city_id?.label,
      });

      setValue("pincode_id", {
        value: res?.data?.pincode_id?.id,
        label: res?.data?.pincode_id?.label,
      });
      props.addressGet();
      // reset(res?.data);
    }
  };

  const { Select2Data } = useContext(Context);
  const [countries, setCountries] = useState();
  const [states, setState] = useState();
  const [cities, setCity] = useState();
  const [pincodes, setPincode] = useState();
  const getCountries = async () => {
    const res = await Country();
    if (res?.success) {
      const data = await Select2Data(res?.data, "country_id", false);
      setCountries(data);
    }
  };
  const getState = async (id) => {
    const res = await State(id);
    if (res?.success) {
      const data = await Select2Data(res?.data, "state_id", false);
      setState(data);
      setValue("state_id", "");
      setValue("city_id", "");
      setValue("pincode_id", "");
    }
  };
  const getCity = async (id) => {
    const res = await City(id);
    if (res?.success) {
      const data = await Select2Data(res?.data, "city_id", false);
      setCity(data);
      setValue("city_id", "");
      setValue("pincode_id", "");
    }
  };
  const getPincode = async (id) => {
    const res = await Pincode(id);
    if (res?.success) {
      const data = await Select2Data(res?.data, "pincode_id", false);
      setPincode(data);
      setValue("pincode_id", "");
    }
  };

  useEffect(() => {
    getCity();
    getCountries();
    getState();
    getPincode();
    getAddress();
  }, []);

  useEffect(() => {
    getSingleAddress();
  }, [props.address_id]);

  return (
    <>
      <div>
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="edit-modallll"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit Address
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="new-adress">
                <Row>
                  <Col xxl={8} xl={8} lg={12} md={8} xs={12}>
                    <Form.Group
                      className="mb-3  form-grp"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        {...register("name", {
                          required: "name",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.name,
                          "is-valid": getValues("name"),
                        })}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3  form-grp"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Name of address*</Form.Label>
                      <div
                        className="address-name-select"
                        style={{ width: "100%" }}
                      >
                        <Controller
                          name="address_type"
                          {...register("address_type", {
                            required: "Select Address Type",
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.address_type
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              options={options}
                            />
                          )}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group
                      className="mb-3  form-grp"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Flat no./House/bldg*</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Flat no./House/bldg"
                        {...register("flat_home_bldg", {
                          required: "Flat no./House/bldg required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.flat_home_bldg,
                          "is-valid": getValues("flat_home_bldg"),
                        })}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3  form-grp"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Colony/street/landmark</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Colony/street/landmark"
                        {...register("col_land_strt", {
                          required: "Colony/street/landmark required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.col_land_strt,
                          "is-valid": getValues("col_land_strt"),
                        })}
                      />
                    </Form.Group>
                  </Col>
                  <Col xxl={4} xl={4} lg={12} md={4} xs={12}>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.1877885133367!2d73.76940577489273!3d18.52041416917967!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2be5a439e4855%3A0xa27b14514af0a4b3!2sProfcyma%20Career%20Solutions%20LLP%7COnline%20MBA%20Programs%7COnline%20Degree%20Programs%7CDistance%20MBA%7CBest%20Online%20Education%20Service%20Provider%7C!5e0!3m2!1sen!2sin!4v1711456588361!5m2!1sen!2sin"
                      width="100%"
                      height="150"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </Col>

                  <Form.Group
                    as={Col}
                    md={6}
                    controlId="formGridMobile"
                    className="mb-3"
                  >
                    <Form.Label>Country</Form.Label>
                    <Controller
                      name="country_id"
                      {...register("country_id", {
                        required: "Select Country",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          placeholder="Country"
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.country_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={countries}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption);
                            getState(selectedOption.value);
                            setValue("country_id", selectedOption);
                          }}
                        />
                      )}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={6}
                    controlId="formGridMobile"
                    className="mb-3"
                  >
                    <Form.Label>State</Form.Label>
                    <Controller
                      name="state_id"
                      {...register("state_id", {
                        required: "Select State",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          placeholder="State"
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.state_id ? "red" : baseStyles,
                            }),
                          }}
                          {...field}
                          options={states}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption);
                            getCity(selectedOption.value);
                            setValue("state_id", selectedOption);
                          }}
                        />
                      )}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={6}
                    controlId="formGridMobile"
                    className="mb-3"
                  >
                    <Form.Label>City</Form.Label>
                    <Controller
                      name="city_id"
                      {...register("city_id", {
                        required: "Select City",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          placeholder="City"
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.city_id ? "red" : baseStyles,
                            }),
                          }}
                          {...field}
                          options={cities}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption);
                            getPincode(selectedOption.value);
                            setValue("city_id", selectedOption);
                          }}
                        />
                      )}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md={6}
                    controlId="formGridMobile"
                    className="mb-3"
                  >
                    <Form.Label>Pincode</Form.Label>
                    <Controller
                      name="pincode_id"
                      {...register("pincode_id", {
                        required: "Select Pincode",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          placeholder="Pincode"
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.pincode_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={pincodes}
                        />
                      )}
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3 "
                    controlId="exampleForm.ControlInput1"
                    as={Col}
                    md={6}
                  >
                    <Form.Label>Mobile number*</Form.Label>

                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.mobile_number,
                        "is-valid": getValues("mobile_number"),
                      })}
                      type="text"
                      name="mobile_number"
                      placeholder="Enter Mobile Number"
                      {...register("mobile_number", {
                        required: "Field is required",
                        minLength: {
                          value: 10,
                          message: "Number should be at least 10 characters",
                        },
                      })}
                      onKeyDown={(event) => {
                        if (!RegxExpression.phone.test(event.key)) {
                          if (event.key !== "Backspace") {
                            event.preventDefault();
                          }
                        }
                      }}
                      maxLength={10}
                    />
                  </Form.Group>
                </Row>
              </div>
              {/* <div className="main-btn-div ">
                <button
                  type="button"
                  className="save-btn"
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </button>
              </div> */}
              <div className="main-btn-classs d-flex ">
                <button
                  type="button"
                  className="save-bntnnnn me-2"
                  onClick={handleSubmit(onSubmit)}
                  // onClick={() => setModalShow(true)}
                >
                  Save
                </button>
                <EditSuccessFul
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
                {/* <button className="save-bntnnnn" onClick={props.onHide}>
                  Close
                </button> */}
              </div>
            </Form>
          </Modal.Body>
          {/* <Modal.Footer>
            <div className="main-btn-classs d-flex ">
              <button
                className="save-bntnnnn me-2"
                onClick={() => setModalShow(true)}
              >
                Save
              </button>
              <EditSuccessFul
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
              <button className="save-bntnnnn" onClick={props.onHide}>
                Close
              </button>
            </div>
          </Modal.Footer> */}
        </Modal>
      </div>
    </>
  );
};

export default EditModal;
