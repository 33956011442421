import React, { useState, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import LogIn from "../LogIn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Successfull_Modal from "../../Successfull_Modal/Successfull_Modal";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import PasswordChecklist from "react-password-checklist";
import { Context } from "../../../utils/context";
import Cookies from "js-cookie";
import { loginCart } from "../../../utils/apis/addToCart/addToCart";
import { Register } from "../../../utils/apis/login/Login";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";
import "../LogIn.css";
function Register_Form() {
  const [modalShow, setModalShow] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [showPasswordOne, setShowPasswordOne] = useState(true);
  const [iconOne, setIconOne] = useState(faEyeSlash);
  const [iconTwo, setIconTwo] = useState(faEyeSlash);
  const navigate = useNavigate();
  const { signin, setSignin, triggerCartUpdate } = useContext(Context);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm();
  const [gender, setGender] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const today = new Date().toISOString().split("T")[0];

  const hideModalWithDelay = () => {
    setTimeout(() => {
      window.location.href = "/";
      setModalShow(false);
    }, 3000);
  };

  const handleLoginClick = () => {
    setShowLogin(true);
  };

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
      setIconOne(showPassword ? faEye : faEyeSlash);
    } else if (field === "reenterPassword") {
      setShowPasswordOne(!showPasswordOne);
      setIconTwo(showPasswordOne ? faEye : faEyeSlash);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z]*$/;
    if (regex.test(value)) {
      setGender(value);
      setError("");
    } else {
      setError("Please enter only alphabetic characters");
    }
  };

  const onSubmit = async (data) => {
    const res = await Register(data);
    if (res?.success) {
      await Cookies.set("purevia_security", res?.data?.token);
      setSignin(true);
      await loginCart();
      await triggerCartUpdate();
      setModalShow(true);
      setTimeout(() => {
        setModalShow(false);
        navigate("/");
      }, 3000);
    } else {
      if (res?.message?.both) {
        setError("email", { message: res?.message?.both });
        setError("contact_no", { message: "" });
      }
      if (res?.message?.email) {
        setError("email", { message: res?.message?.email });
      }
      if (res?.message?.contact_no) {
        setError("contact_no", { message: res?.message?.contact_no });
      }
    }
  };

  return (
    <>
      {showLogin ? (
        <LogIn />
      ) : (
        <div className="LogIn">
          <div className="row bg-color me-0 ms-0">
            <div className="col-md-6 col-12 p-0">
              <div className="row justify-content-center me-0 ms-0">
                <div className="col-xl-7 col-md-7 col-sm-10">
                  <div className="back_to_span back_to_span2">
                    <Link to={"/"}>
                      <p>Back to Home</p>
                    </Link>
                  </div>
                  <div className="heading-holder heading-holder2">
                    <h4>Register</h4>
                  </div>
                  <div className="log_in_form">
                    <Form>
                      <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="first_name"
                          placeholder="Enter First Name"
                          {...register("first_name", {
                            required: "Enter Your Name",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.first_name,
                            "is-valid": getValues("first_name"),
                          })}
                          onKeyDown={(event) => {
                            if (!/^[a-zA-Z]*$/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors?.first_name && (
                          <sup className="text-danger">
                            {errors?.first_name?.message}
                          </sup>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Gender</Form.Label>
                        <Form.Control
                          type="text"
                          name="gender"
                          placeholder="Enter Gender"
                          {...register("gender", {
                            required: "Gender is required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.gender,
                            "is-valid": getValues("gender"),
                          })}
                          onKeyDown={(event) => {
                            if (!/^[a-zA-Z]*$/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors?.gender && (
                          <sup className="text-danger">
                            {errors?.gender?.message}
                          </sup>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="dob">
                        <Form.Label>Date of Birth</Form.Label>
                        <Form.Control
                          type="date"
                          name="dob"
                          placeholder="Enter Date Of Birth"
                          max={today}
                          {...register("dob", {
                            required: "Date Of Birth is required",
                            validate: (value) =>
                              value <= today || "Future dates are not allowed",
                          })}
                          className={classNames("date-input", {
                            "is-invalid": errors?.dob,
                            "is-valid": getValues("dob"),
                          })}
                        />
                        {errors?.dob && (
                          <sup className="text-danger">
                            {errors?.dob?.message}
                          </sup>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="text"
                          name="email"
                          placeholder="Enter Email"
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                              message: "Invalid email address",
                            },
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.email,
                            "is-valid": getValues("email"),
                          })}
                        />
                        {errors?.email && (
                          <sup className="text-danger">
                            {errors?.email?.message}
                          </sup>
                        )}
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>Contact No</Form.Label>
                        <Form.Control
                          type="text"
                          name="contact_no"
                          placeholder="Enter Mobile Number"
                          {...register("contact_no", {
                            required: "Contact Number is required",
                            maxLength: {
                              value: 10,
                              message: "Number should not exceed 10 digits", // Updated for clarity
                            },
                            minLength: {
                              value: 10,
                              message: "Number should be at least 10 digits", // Added minLength validation
                            },
                            pattern: {
                              value: /^[0-9]*$/,
                              message: "Only digits are allowed", // Added pattern validation to ensure digits only
                            },
                          })}
                          onKeyDown={(event) => {
                            if (
                              !/^\d*$/.test(event.key) &&
                              event.key !== "Backspace"
                            ) {
                              event.preventDefault();
                            }
                          }}
                          maxLength={10} // This works with input constraint; ensure it matches validation
                          className={classNames("", {
                            "is-invalid": errors?.contact_no,
                            "is-valid": getValues("contact_no")?.length === 10, // Validate only if the number is complete
                          })}
                        />
                        {errors?.contact_no && (
                          <sup className="text-danger">
                            {errors?.contact_no?.message}
                          </sup>
                        )}
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <div className="password-field-holder">
                          <Form.Control
                            type={showPassword ? "password" : "text"}
                            placeholder="Enter Password*"
                            name="password"
                            {...register("password", {
                              required: "Password is required",
                              minLength: {
                                value: 8,
                                message:
                                  "Password must be at least 8 characters long",
                              },
                              pattern: {
                                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                message:
                                  "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                              },
                            })}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <div className="eye-icon-holder">
                            <FontAwesomeIcon
                              icon={iconOne}
                              className="eye-icon"
                              onClick={() =>
                                togglePasswordVisibility("password")
                              }
                            />
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Confirm Password</Form.Label>
                        <div className="password-field-holder">
                          <Form.Control
                            type={showPasswordOne ? "password" : "text"}
                            placeholder="Re-Enter Password*"
                            name="re_password"
                            {...register("re_password", {
                              required: "Re-Password is required",
                              validate: (value) =>
                                value === password || "Passwords do not match",
                            })}
                            onChange={(e) => setPasswordAgain(e.target.value)}
                          />
                          <div className="eye-icon-holder">
                            <FontAwesomeIcon
                              icon={iconTwo}
                              className="eye-icon"
                              onClick={() =>
                                togglePasswordVisibility("reenterPassword")
                              }
                            />
                          </div>
                        </div>
                        {errors?.re_password && (
                          <sup className="text-danger">
                            {errors?.re_password?.message}
                          </sup>
                        )}
                      </Form.Group>
                      {password && (
                        <PasswordChecklist
                          rules={[
                            "minLength",
                            "specialChar",
                            "number",
                            "capital",
                            "match",
                          ]}
                          minLength={8}
                          value={password}
                          valueAgain={passwordAgain}
                          onChange={(isValid) => {}}
                          style={{
                            fontSize: "10px",
                            padding: "4px",
                            margin: "0",
                          }}
                        />
                      )}
                    </Form>
                    <div className="btn-holder">
                      <Common_Button
                        className="SubmitBtn"
                        text="Create"
                        onClick={handleSubmit(onSubmit)}
                      />
                    </div>
                    <div className="create_account">
                      <div className="login_text">
                        <p>
                          Already have an account?{" "}
                          <span
                            className="login_text"
                            onClick={handleLoginClick}
                          >
                            LOGIN
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0">
              <div className="img-section">
                <div className="img-holder">
                  <img
                    className="log_in_img"
                    src={`${process.env.PUBLIC_URL}/assets/Image/myaccount/grppp.png`}
                    alt="log_in_img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Successfull_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        namess="Your account has been successfully created"
      />
    </>
  );
}
export default Register_Form;
