import React, { useState, useEffect, useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./HomeBanner.css";
import { Container } from "react-bootstrap";
import BrowsCategory from "../BrowsCategory/BrowsCategory";
import BrowsCategoryMobile from "../BrowsCategory/BrowsCategoryMobile";
import { Banners } from "../../../utils/apis/master/Master";
import { Context } from "../../../utils/context";
import { Autoplay } from "swiper/modules";

const HomeBanner = () => {
  const { IMG_URL } = useContext(Context);
  const [data, setData] = useState();

  const getAllData = async () => {
    const res = await Banners();
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error("Error fetching data:", res?.error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const isVideo = (filename) => {
    const videoExtensions = ["mp4", "webm", "ogg"];
    const extension = filename.split(".").pop();
    return videoExtensions.includes(extension);
  };

  return (
    <>
      <section className="Home-Banner">
        <Container>
          <div>
            <Swiper
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              loop={true}
              autoplay={{ delay: 2500 }}
              modules={[Autoplay]}
              className="mySwiper"
            >
              {data?.map((slide, index) => (
                <SwiperSlide key={index}>
                  {isVideo(slide.image) ? (
                    <video
                      className="Banner-image vdo-holder"
                      autoPlay={true}
                      muted
                      playsInline
                      loop
                    >
                      <source src={IMG_URL + slide.image} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img
                      className="Banner-image"
                      src={IMG_URL + slide.image}
                      alt="Banner"
                    />
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="home-nav-tab">{/* <BrowsCategory /> */}</div>
          {/* <div className="home-nav-tab-mobile">
            <BrowsCategoryMobile />
          </div> */}
        </Container>
      </section>
    </>
  );
};

export default HomeBanner;
