import React from "react";
import "./Cancle_Reason_Modal.css";
import { Form, Modal } from "react-bootstrap";

function Cancle_Reason_Modal(props) {
  const handleReason = async (d) => {
    await props.setReason(d);
    props.onHide();
  };

  return (
    <>
      <div className="Reason_Modal">
        <Modal
          className=" Cancel_Reason_Modal"
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton> {props.headername}</Modal.Header>
          <Modal.Body>
            <div className="select_reason">
              <Form>
                <Form.Check
                  label="Product damaged, but shipping box OK"
                  name="name"
                  type="radio"
                  value="Product damaged, but shipping box OK"
                  onClick={() =>
                    handleReason("Product damaged, but shipping box OK")
                  }
                  defaultChecked={
                    props.reason === "Product damaged, but shipping box OK"
                  }
                />

                <Form.Check
                  label="Both product and shipping box damaged"
                  name="name"
                  type="radio"
                  value="Both product and shipping box damaged"
                  onClick={() => {
                    handleReason("Both product and shipping box damaged");
                    props.setErrorMessage("");
                  }}
                  defaultChecked={
                    props.reason === "Both product and shipping box damaged"
                  }
                />
                <Form.Check
                  label="Wrong item was sent"
                  name="name"
                  type="radio"
                  value="Wrong item was sent"
                  onClick={() => {
                    handleReason("Wrong item was sent");
                    props.setErrorMessage("");
                  }}
                  defaultChecked={props.reason === "Wrong item was sent"}
                />
                <Form.Check
                  label="Item defective or doesn’t work"
                  name="name"
                  type="radio"
                  value="Item defective or doesn’t work"
                  onClick={() => {
                    handleReason("Item defective or doesn’t work");
                    props.setErrorMessage("");
                  }}
                  defaultChecked={
                    props.reason === "Item defective or doesn’t work"
                  }
                />

                <Form.Check
                  label="Product not required anymore"
                  name="name"
                  type="radio"
                  value="Product not required anymore"
                  onClick={() => {
                    handleReason("Product not required anymore");
                    props.setErrorMessage("");
                  }}
                  defaultChecked={
                    props.reason === "Product not required anymore"
                  }
                />

                <Form.Check
                  label="Damaged Product"
                  name="name"
                  type="radio"
                  value="Damaged Product"
                  onClick={() => {
                    handleReason("Damaged Product");
                    props.setErrorMessage("");
                  }}
                  defaultChecked={props.reason === "Damaged Product"}
                />

                <Form.Check
                  label="Expired Product"
                  name="name"
                  type="radio"
                  value="Expired Product"
                  onClick={() => {
                    handleReason("Expired Product");
                    props.setErrorMessage("");
                  }}
                  defaultChecked={props.reason === "Expired Product"}
                />

                <Form.Check
                  label="Change of Mind"
                  name="name"
                  type="radio"
                  value="Change of Mind"
                  onClick={() => {
                    handleReason("Change of Mind");
                    props.setErrorMessage("");
                  }}
                  defaultChecked={props.reason === "Change of Mind"}
                />

                <Form.Check
                  label="Price Change"
                  name="name"
                  type="radio"
                  value="Price Change"
                  onClick={() => {
                    handleReason("Price Change");
                    props.setErrorMessage("");
                  }}
                  defaultChecked={props.reason === "Price Change"}
                />
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default Cancle_Reason_Modal;
