import React, { useState, useEffect, useContext } from "react";
import "./Aboutbanner.css";
import { Container } from "react-bootstrap";
import { Context } from "../../../utils/context";
import parse from "html-react-parser";
import { AboutBanners } from "../../../utils/apis/about/aboutApis";
function Aboutbanner() {
  const { IMG_URL } = useContext(Context);
  const [data, setData] = useState();
  const getAllData = async () => {
    const res = await AboutBanners();
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }
  useEffect(() => {
    getAllData();
  }, []);
  return (
    <>
      <section className="AboutBanner-section">
        <Container>
          <div className='aboutbanner'>
            <img
              className='about-img'
              // src={process.env.PUBLIC_URL + '/assets/Image/about/abtbanner.png'} 
              src={IMG_URL + data?.image}
              alt="Banner Image"
            />
          </div>
        </Container>
      </section>
    </>
  );
}

export default Aboutbanner;
