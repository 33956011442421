import React from 'react';
import './App.css';
import './index.css';
import { Route, Routes } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import ScrollToTop from './components/ScrollTotop/ScrollTotop';
import HomePage from './components/HomePage/HomePage'
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Blog from './components/Blog/Blog';
import BlogInner from './components/Blog/BlogInner/BlogInner';
import ProductDetails from './components/Product-Details/ProductDetails';

// import MyAccount from './components/MyAccount/MyAccount';
import MyAccount from './components/MyAccount/MyAccount'
import AccountInfo from './components/MyAccount/AccountInfo/AccountInfo';
import ChangePassword from './components/MyAccount/ChangePassword/ChangePassword';
import OrderHistory from './components/MyAccount/OrderHistory/OrderHistory';
import CheckoutProcess from './components/CheckoutProcess/CheckoutProcess';
import OrderReturn from './components/MyAccount/Account/Order-return/OrderReturn'
import OrderReturnHistory from "./components/MyAccount/OrderHistory/order-return-history/OrderReturnHistory";

import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import TermsCondition from './components/TermsCondition/TermsCondition';
import ReturnPolicy from './components/ReturnPolicy/ReturnPolicy';
import Disclaimer from './components/Disclaimer/Disclaimer';
import WishList from './components/WishList/WishList';
import Cart from './components/Cart/Cart';
import LogIn from './components/LogIn/LogIn';
import HideNavbar from './components/HideNavbar/HideNavbar';
import AppContext from "./utils/context";
import BecomeSeller from './components/BecomeSeller/BecomeSeller';
function App() {
  return (
    <>
      <AppContext>
        <ScrollToTop />
        <HideNavbar>
          <Header />
        </HideNavbar>

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/becomeseller" element={<BecomeSeller />} />
          <Route path="/bloginner/:id" element={<BlogInner />} />
          <Route path="/product-details/:id" element={<ProductDetails />} />

          {/* My Account & Inner Page */}
          <Route path="/my-account" element={<MyAccount />} />
          <Route path="/order-history" element={<OrderHistory />} />
          <Route path="/information" element={<AccountInfo />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />
          <Route path="/order-return-history" element={< OrderReturnHistory />} />
          <Route path="/order-return/:orderId" element={< OrderReturn />} />

          <Route path="/checkout" element={<CheckoutProcess />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route path="/return-policy" element={<ReturnPolicy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/wishlist" element={<WishList />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/login" element={<LogIn />} />
        </Routes>

        <HideNavbar>
          <Footer />
        </HideNavbar>
      </AppContext>
    </>
  );
}

export default App;
