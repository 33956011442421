import React, { useState, useEffect, useContext } from "react";
import "./Information.css";
import { Col, Container, Form, Row, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";

import { RegxExpression } from "../../../../utils/common";

import { UserGet, UserEdit } from "../../../../utils/apis/myAccount/myAccount";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";

// import UpdateSuccess from "../../../Common_modal/UpdateSuccess/UpdateSuccess"

import UpdateSuccess from "../../../Common-Component/UpdateSuccess/UpdateSuccess";

function Information() {
  const [editStatus, setEditStatus] = useState(true);
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const today = new Date().toISOString().split("T")[0];

  const [modalShow, setModalShow] = useState(false);
  const onSubmit = async (data) => {
    const res = await UserEdit(data);
    if (res?.success) {
      setModalShow(true);
      setTimeout(() => {
        setModalShow(false);
        setEditStatus(true);
      }, 3000);
    } else {
      if (res?.message?.both) {
        setError("email", { message: res?.message?.both });
        setError("contact_no", { message: "" });
      }
      if (res?.message?.email) {
        setError("email", { message: res?.message?.email });
      }
      if (res?.message?.contact_no) {
        setError("contact_no", { message: res?.message?.contact_no });
      }
    }
  };

  const handleEdit = async () => {
    setEditStatus(!editStatus);
    getUser();
  };

  const [userData, setUserData] = useState();
  const getUser = async () => {
    const res = await UserGet();
    if (res?.success) {
      setUserData(res.data);
      reset(res?.data);
    }
  };
  useEffect(() => {
    getUser();
  }, []);
  return (
    <>
      <section className="account-information my-account ">
        <div className="container">
          <div className="heading_holder">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={"/my-account"}>My account</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <Link to={"/information"}>Account Information </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="back-text-holder">
            <Link to={"/my-account"}>
              <p>Back</p>
            </Link>
          </div>

          <div>
            <h2 className="title">Account Information</h2>
          </div>

          <div className="form-section">
            <div className="row justify-content-center">
              <div className="col-xxl-5 col-lg-6 mb-3">
                <div className="account-form">
                  <div className="row mb-3">
                    <div className="col-md-11 col-10">
                      <h4 className="form-title">Account Deatils</h4>
                    </div>
                    <div className="col-md-1 col-2">
                      <div>
                        <h6 className="edit" onClick={handleEdit}>
                          Edit
                        </h6>
                      </div>
                    </div>
                  </div>
                  <Form>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" className="label-set">
                        Name*
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          disabled={editStatus}
                          type="text"
                          name="first_name"
                          placeholder="Enter First Name"
                          {...register("first_name", {
                            required: "First name required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.first_name && !editStatus,
                            "is-valid": getValues("first_name") && !editStatus,
                          })}
                          onKeyDown={(event) => {
                            if (!RegxExpression.name.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors?.first_name && (
                          <div className=" mt-2">
                            <sup className="text-danger  ">
                              {errors?.first_name?.message}
                            </sup>
                          </div>
                        )}
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" className="label-set">
                        Mobile Number*
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          disabled={editStatus}
                          className={classNames("", {
                            "is-invalid": errors?.contact_no && !editStatus,
                            "is-valid": getValues("contact_no") && !editStatus,
                          })}
                          type="text"
                          name="contact_no"
                          placeholder="Enter Mobile Number"
                          {...register("contact_no", {
                            required: "Field is required",
                            minLength: {
                              value: 10,
                              message:
                                "Number should be at least 10 characters",
                            },
                          })}
                          onKeyDown={(event) => {
                            if (!RegxExpression.phone.test(event.key)) {
                              if (event.key !== "Backspace") {
                                event.preventDefault();
                              }
                            }
                          }}
                          maxLength={10}
                        />
                        {errors?.contact_no && (
                          <div className=" mt-2">
                            <sup className="text-danger  ">
                              {errors?.contact_no?.message}
                            </sup>
                          </div>
                        )}
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" className="label-set">
                        Email
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          disabled={editStatus}
                          type="text"
                          name="email"
                          placeholder="Enter Email"
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              value: RegxExpression.email,
                              message: "Invalid email address",
                            },
                          })}
                          className={classNames("fcontrl", {
                            "is-invalid": errors?.email && !editStatus,
                            "is-valid": getValues("email") && !editStatus,
                          })}
                        />
                        {errors?.email && (
                          <div className=" mt-2">
                            <sup className="text-danger  ">
                              {errors?.email?.message}
                            </sup>
                          </div>
                        )}
                        {/* <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                          }}
                        >
                          <span style={{ color: "green", fontSize: "1.2em" }}>
                            ✔
                          </span>
                        </div> */}
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" className="label-set">
                        Gender
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          disabled={editStatus}
                          type="text"
                          name="gender"
                          placeholder="Enter Gender"
                          {...register("gender", {
                            required: "gender is required",
                            pattern: {
                              value: RegxExpression.gender,
                              message: "Invalid gender address",
                            },
                          })}
                          className={classNames("fcontrl", {
                            "is-invalid": errors?.gender && !editStatus,
                            "is-valid": getValues("gender") && !editStatus,
                          })}
                        />
                        {errors?.gender && (
                          <div className=" mt-2">
                            <sup className="text-danger  ">
                              {errors?.gender?.message}
                            </sup>
                          </div>
                        )}
                        {/* <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                          }}
                        >
                          <span style={{ color: "green", fontSize: "1.2em" }}>
                            ✔
                          </span>
                        </div> */}
                      </Col>
                    </Form.Group>

                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextPassword"
                    >
                      <Form.Label column sm="4" className="label-set">
                        Date Of Birth
                      </Form.Label>
                      <Col sm="8">
                      <div className="dtbb">

                      
                        <Form.Control
                       
                          disabled={editStatus}
                          type="date"
                          name="dob"
                          max={today}
                          placeholder="Enter Date Of Birth"
                          {...register("dob", {
                            required: "Date Of Birth is required",
                            pattern: {
                              value: RegxExpression.dob,
                              message: "Invalid dob ",
                            },
                          })}
                          className={classNames("fcontrl", {
                            "is-invalid": errors?.dob && !editStatus,
                            "is-valid": getValues("dob") && !editStatus,
                          })}
                        />
                        {errors?.dob && (
                          <div className=" mt-2">
                            <sup className="text-danger  ">
                              {errors?.dob?.message}
                            </sup>
                          </div>
                        )}
                        {/* <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "10px",
                            transform: "translateY(-50%)",
                          }}
                        >
                          <span style={{ color: "green", fontSize: "1.2em" }}>
                            ✔
                          </span>
                        </div> */}
                        </div>
                      </Col>
                    </Form.Group>

                    {!editStatus && (
                      <Common_Button
                        text={"Update"}
                        className="update"
                        onClick={handleSubmit(onSubmit)}
                      />
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <UpdateSuccess
        show={modalShow}
        onHide={() => setModalShow(false)}
        text={"Data Updated SuccessFully"}
      />
    </>
  );
}

export default Information;
