import React, { useContext, useEffect, useRef, useState } from "react";
import "./Counters.css";
import CountUp from "react-countup";
import { Col, Container, Row } from "react-bootstrap";
import { Context } from "../../../utils/context";
function Counters() {
  const { getData } = useContext(Context);

  const [countProducts, setCountProducts] = useState("");
  const GetProductData = async () => {
    const res = await getData(`/without-login/home/web-products`);
    setCountProducts(res?.data?.count);
  };

  const [countCustomers, setCountCustomers] = useState("");
  const GetCustomersData = async () => {
    const res = await getData(`/without-login/home/web-customer`);
    setCountCustomers(res?.data?.count);
  };

  useEffect(() => {
    GetProductData();
    GetCustomersData();
  }, []);

  //   const cardsData = [
  //     {
  //       number: countProducts,
  //       types: "Products",
  //       icon: "fa-solid fa-star",
  //       className: "#63bff5",
  //       api: "/product",
  //     },
  //     {
  //       number: countCustomers,
  //       types: "Customers",
  //       icon: "fa-solid fa-user",
  //       className: "#63bff5",
  //       api: "/employee/customer-details",
  //     },
  //     // {
  //     //     number: countSellers,
  //     //     types: "Sellers",
  //     //     icon: "fa-solid fa-users",
  //     //     className: "#63bff5"
  //     // },
  //   ];
  return (
    <>
      <section class="counter-section">
        <Container>
          <Row>
            <Col md={10} className="mx-auto">
              <Row>
                <Col md={4} sm={4} xs={4}>
                  <div class="counter-circle">
                    <h1 class="numbers">
                      <span id="Customers" class="numbers"></span>{" "}
                      <CountUp end={countCustomers} duration={5} /> +{" "}
                    </h1>
                  </div>
                  <div class="text-center">
                    <h6 class="counter-text">Customers</h6>
                  </div>
                </Col>
                <Col md={4} sm={4} xs={4}>
                  <div class="counter-circle">
                    <h1 class="numbers">
                      <span id="Member" class="numbers">
                        {" "}
                      </span>{" "}
                      <CountUp end={countProducts} duration={5} /> +
                    </h1>
                  </div>
                  <div class="text-center">
                    <h6 class="counter-text">Products</h6>
                  </div>
                </Col>
                <Col md={4} sm={4} xs={4}>
                  <div class="counter-circle">
                    <h1 id="Support" class="numbers">
                      24hr
                    </h1>
                  </div>
                  <div class="text-center">
                    <h6 class="counter-text">Support Available</h6>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Counters;
