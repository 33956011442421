import React, { useState, useEffect, useContext } from "react";
import { Breadcrumb, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import PurchasedItemsReturnModal from "../../../AllModal/Purchased_Items_Modal/PurchasedItemReturnModal";
import {
  orderGet,
  returnOrderGet,
} from "../../../../utils/apis/myAccount/myAccount";

const OrderReturnHistory = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [data, setData] = useState();

  // const [orders, setOrder] = useState([]);
  // const getOrders = async () => {
  //     const res = await orderGet();
  //     if (res?.success) {
  //         setOrder(res?.data);
  //     }
  // }

  // useEffect(() => {
  //     getOrders();
  // }, []);

  const [returnOrders, setReturnOrder] = useState([]);
  const getReturnOrders = async () => {
    const res = await returnOrderGet();
    if (res?.success) {
      setReturnOrder(res?.data);
    }
  };

  const getDeliveryDatePlusThreeDays = (deliveryDate) => {
    const date = new Date(deliveryDate);
    date.setDate(date.getDate() + 3);
    return date.toISOString().split("T")[0]; // Adjust the format as needed
  };

  useEffect(() => {
    getReturnOrders();
  }, []);

  return (
    <>
      <section className="my-account checkout-inner-steps ">
        <div className="container">
          <div className="account-table confirm_order Product_Cart">
            <div className="heading_holder">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to={"/"}>Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to={"/my-account"}>My account</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <Link to={"/order-history"}>Order history</Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="back-text-holder">
              <Link to={"/my-account"}>
                <p>Back</p>
              </Link>
            </div>

            <div className="product_table">
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Order No.</th>
                    <th>Products Name</th>
                    <th>Deliverd Date</th>
                    <th>Expected Pickup Date</th>
                    <th>Total</th>
                    <th>Payment Type</th>
                    {/* <th>Payment Status</th> */}
                    <th>Return Status</th>
                    <th>Return Status Date</th>
                    <th>Order Progress</th>
                  </tr>
                </thead>
                <tbody>
                  {returnOrders.length > 0
                    ? returnOrders?.map((value, index) => (
                        <>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{value?.invoice_number}</td>
                            <td>
                              {value?.orders_details.map(
                                (orderDetail, index) => (
                                  <span key={index}>
                                    {orderDetail.product?.name}
                                    {index !==
                                      value.orders_details.length - 1 && ", "}
                                  </span>
                                )
                              )}
                            </td>

                            <td>{value?.delivery_date}</td>
                            <td>
                              {value?.delivery_date
                                ? getDeliveryDatePlusThreeDays(
                                    value.delivery_date
                                  )
                                : ""}
                            </td>

                            <td>₹ {value?.amount}</td>
                            <td>{value?.payment_mode}</td>
                            {/* <td className="green-text">
                              {value?.payment_status?.status === 1
                                ? "Paid"
                                : "UnPaid"}
                            </td> */}
                            <td className="red-text">
                              {value?.return_order?.return_status?.name}
                            </td>

                            <td>
                              {(function () {
                                if (!value?.updatedAt) return "";
                                const date = new Date(value.updatedAt);
                                date.setDate(date.getDate() + 3);
                                return date.toISOString().split("T")[0];
                              })()}
                            </td>
                            <td
                              className="view-text"
                              onClick={() => {
                                setData(value);
                                setModalShow(true);
                              }}
                            >
                              View
                            </td>
                          </tr>
                        </>
                      ))
                    : "No Order To Return"}
                  {/* {returnOrders?.map((value, index) => (
                    <>
                      <tr>
                        <td>{index + 1}</td>
                        <td>{value?.invoice_number}</td>
                        <td>{value?.delivery_date}</td>
                        <td>
                          {value?.delivery_date
                            ? getDeliveryDatePlusThreeDays(value.delivery_date)
                            : ""}
                        </td>

                        <td>₹ {value?.amount}</td>
                        <td>{value?.payment_mode}</td>
                        <td className="green-text">
                          {value?.payment_status?.status === 1
                            ? "Paid"
                            : "UnPaid"}
                        </td>
                        <td className="red-text">
                          {value?.return_order?.return_status?.name}
                        </td>

                        <td>
                          {(function () {
                            if (!value?.updatedAt) return "";
                            const date = new Date(value.updatedAt);
                            date.setDate(date.getDate() + 3);
                            return date.toISOString().split("T")[0];
                          })()}
                        </td>
                        <td
                          className="view-text"
                          onClick={() => {
                            setData(value);
                            setModalShow(true);
                          }}
                        >
                          View
                        </td>
                      </tr>
                    </>
                  ))} */}
                </tbody>
              </Table>

              <div className="span-holder">
                {/* <span>Showing 3 of 3 entries</span> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <PurchasedItemsReturnModal
        data={data}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default OrderReturnHistory;
