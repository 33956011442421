import React, { useState, useEffect, useContext } from "react";
import BlogInnerBanner from './BlogInnerBanner/BlogInnerBanner'
import BlogInnerText from './BlogInnerText/BlogInnerText'
import { Context } from "../../../utils/context";
import parse from "html-react-parser";
import { getSingleBlog } from "../../../utils/apis/common/Common";
import { useParams } from "react-router-dom";
const BlogInner = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState();
  const getProject = async () => {
    const res = await getSingleBlog(id);
    if (res?.success) {
      setBlog(res?.data);
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }

  useEffect(() => {
    getProject();

  }, []);
  return (
    <>
      <BlogInnerBanner blog={blog} />
      <BlogInnerText blog={blog} />
    </>
  )
}

export default BlogInner