import React, { useState, useEffect, useContext } from "react";
import BlogPost from "./BlogPost/BlogPost";
import { Context } from "../../utils/context";
import parse from "html-react-parser";
import { getAllBlog } from "../../utils/apis/common/Common";
const Blog = () => {
  const { IMG_URL } = useContext(Context);
  const [blog, setBlog] = useState();
  const [recentpost, setRecentpost] = useState();
  const getProject = async () => {
    const res = await getAllBlog();
    if (res?.success) {
      setBlog(res?.data?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };
  const getProjectTwo = async () => {
    const res = await getAllBlog();
    if (res?.success) {
      setRecentpost(res?.data?.recent_post);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getProject();
    getProjectTwo();
  }, []);
  return (
    <>
      <BlogPost recentpost={recentpost} blog={blog} />
    </>
  );
};

export default Blog;
