import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { Context } from '../../utils/context';
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";

const LogOutModal = (props) => {
  const navigate = useNavigate();

  const {
    cartCount,
    signin,
    setSignin,
    wishlistData,
    IMG_URL,
    triggerCartUpdate,
    wishlistCount,
    triggerWishlistUpdate,
    setAddCartModal,
    addCartModal,
    getData,
  } = useContext(Context);

  const handleLogout = async () => {
    await Cookies.remove("purevia_security", { path: "/" });
    await setSignin(false);

    await navigate("/");
    props.onHide();
    window.location.reload();
  };
  return (
    <Modal
    className='lottimodal'
    {...props}
    size="sm"
    aria-labelledby="contained-modal-title-vcenter"
    centered
>
    <Modal.Header closeButton>
    </Modal.Header>
    <Modal.Body>
        <p className='text-center modal-text-class'>Are you sure to logout from <span className='netpurti-btn'>Purevia </span>account? </p>
        <div className='text-center'>
            <button onClick={props.onHide} className='cancel-btn me-3' >Cancel</button>
            <button onClick={handleLogout} className='Logout-btn'>Logout</button>            
        </div>
    </Modal.Body>

</Modal>
  )
}

export default LogOutModal