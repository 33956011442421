import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Container, Row, Col } from "react-bootstrap";
import ProdcutDescription from "./ProdcutDescription/ProdcutDescription";
import RelatedProdcut from "./RelatedProdcut/RelatedProdcut";
import ProcductFeature from "./ProdcutFeature/ProcductFeature";
import ProductCategory from "./ProductCategory/ProductCategory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { getProductDetail } from "../../utils/apis/Product/Product";
import { getCartCheck } from "../../utils/apis/addToCart/addToCart";
import { Context } from "../../utils/context";
import Cookies from "js-cookie";

const ProductDetails = () => {
  const { id } = useParams();
  const [storedProductId, setStoredProductId] = useState(id);

  const { signin, variant, setChangeQuantity, changeQuantity } =
    useContext(Context);
  const navigate = useNavigate();
  const [product, setProduct] = useState();
  const [cartStatus, setCartStatus] = useState(false);
  const [cartData, setCartData] = useState();
  const [variantId, setVariantId] = useState("");
  const getProduct = async () => {
    // const storedProductId = localStorage.getItem('product_id');

    const res = await getProductDetail(id);
    if (res?.success) {
      setProduct(res?.data);
      var response;
      if (Cookies.get("purevia_security")) {
        const data = { product_id: id };
        response = await getCartCheck(data);
      } else {
        const data = { product_id: id };
        response = await getCartCheck(data);
      }
      if (response?.success) {
        if (response?.cartItem?.product_id === product?.id) {
          await setCartStatus(true);
          setCartData(response?.cartItem);
        } else {
          await setCartStatus(false);
          setCartData("");
        }
      }
    }
  };
  useEffect(() => {
    getProduct();
  }, [id]);

  return (
    <>
      <section>
        <Container>
          <Breadcrumb className="mt-4">
            <Breadcrumb.Item>
              <Link to="/">
                <FontAwesomeIcon
                  icon="fa-solid fa-house"
                  className="link-bredcumb"
                />
              </Link>{" "}
              <FontAwesomeIcon
                icon="fa-solid fa-angle-right"
                className="link-bredcumb"
              />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/" className="link-bredcumb">
                {" "}
                Featured Products{" "}
              </Link>
              <FontAwesomeIcon
                icon="fa-solid fa-angle-right"
                className="link-bredcumb"
              />
            </Breadcrumb.Item>
            {/* <Breadcrumb.Item active>Pen</Breadcrumb.Item> */}
          </Breadcrumb>
          <Row>
            <Col xxl={12} xl={12} lg={12} md={12}>
              <ProcductFeature
                product={product}
                cartStatus={cartStatus}
                setCartStatus={setCartStatus}
                cartData={cartData}
                getProduct={getProduct}
                setVariantId={setVariantId}
                setCartData={setCartData}
              />
              <ProdcutDescription product={product} />
              <RelatedProdcut />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ProductDetails;
