import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useContext } from "react";
import "./BlogPost.css";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import BlogInner from "../BlogInner/BlogInner";
import { Context } from "../../../utils/context";
import parse from "html-react-parser";
import { getAllBlog } from "../../../utils/apis/common/Common";

function BlogPost({ blog, recentpost }) {
  const { IMG_URL } = useContext(Context);

  return (
    <>
      <section className="blog-section">
        <Container>
          <Row>
            <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
              {blog?.map((content) => (
                <div className="blog-rights">
                  <Row>
                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                      <div className="rights-imgdiv">
                        <img
                          className="rightimg"
                          src={IMG_URL + content?.image}
                          alt="Logo"
                        />
                      </div>
                    </Col>

                    <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                      <div className="blog-rightcontet">
                        <p className="blogdate">
                          {new Date(content?.createdAt).toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            }
                          )}
                        </p>
                        <h6 className="blogheads">{content.name}</h6>
                        <p className="blogtxts">
                          {parse(content.description) || ""}
                        </p>
                        <Link to={`/bloginner/${content?.id}`}>
                          <p className="readmore">
                            Read More
                            <FontAwesomeIcon
                              icon="fa-solid fa-arrow-right "
                              className="aro"
                            />
                          </p>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}
            </Col>

            <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
              <div className="scoll">
                <div className="heads mb-3">
                  <h5>Recent Posts</h5>
                </div>
                <div className="scrollbar-div">
                  <Row>
                    {recentpost?.map((contents2) => (
                      <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Row className="mb-3">
                          <Col xxl={3} xl={3} lg={3} md={2} sm={2} xs={2}>
                            <div className="scroll-imagediv">
                              <img
                                className="scroll-image"
                                src={IMG_URL + contents2.image}
                              />
                            </div>
                          </Col>
                          <Col
                            xxl={9}
                            xl={9}
                            lg={9}
                            md={10}
                            sm={10}
                            xs={10}
                            className="my-auto"
                          >
                            <div className="scroll-cont">
                              <Link to={`/bloginner/${contents2?.id}`}>
                                {" "}
                                <div className="scroll-top-con">
                                  <p className="top-p">{contents2.name}</p>
                                  {/* <p className="scroll-bottom-con scroll-p2">
                                  {parse(contents2.description) || ""}
                                </p> */}
                                  <p className="datecreate">
                                    {contents2?.createdAt
                                      ? new Date(
                                          contents2?.createdAt
                                        ).toLocaleString()
                                      : ""}
                                  </p>
                                </div>
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default BlogPost;
