import React, { useState, useEffect, useContext } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination } from 'swiper/modules';

import "./WhyChoose.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WhyChooseUs } from "../../../utils/apis/about/aboutApis";
import { Context } from "../../../utils/context";
import { Container } from "react-bootstrap";
import { parseXML } from "jquery";
const WhyChoose = () => {
  const features = [
    {
      icons: "fa-solid fa-truck-fast",
      title: "Free Shipping",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industr.",
    },
    {
      icons: "fa-solid fa-circle-dollar-to-slot",
      title: "100% Money Back Guarantee",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industr.",
    },
    {
      icons: "fa-solid fa-headset",
      title: "Online Support 24/7",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industr.",
    },
  ];

  const { IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const getAllData = async () => {
    const res = await WhyChooseUs();
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };
  useEffect(() => {
    getAllData();
  }, []);
  return (
    <>
      <section className="why-choose">
        <div className="text-center">
          <h3 className="hot-title">Why Choose Us</h3>
          <div className="title-border"></div>
        </div>
        <div className="container mt-5">
          <div className="row">
          <Swiper
              slidesPerView={3}
              spaceBetween={8}
              pagination={{ clickable: true }}
              modules={[Pagination]}
              className="why-choose_mySwiper"
              breakpoints={{
                // when window width is >= 640px
                0: {
                  slidesPerView: 1,
                  spaceBetween: 8,
                },
                // when window width is >= 768px
                321: {
                  slidesPerView: 1,
                  spaceBetween: 8,
                },

                485: {
                  slidesPerView: 1,
                  spaceBetween: 8,
                },

                768: {
                  slidesPerView: 2,
                  spaceBetween: 8,
                },

                992: {
                  slidesPerView: 2,
                  spaceBetween: 8,
                },
                // when window width is >= 1024px
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 5,
                },
              }}
            >
            {data.map((feature, index) => (
              <SwiperSlide>
              <div key={index} className="bd-right">
                <div className="d-flex choos-col">
                  <div className="choose-fafa-bg text-center">
                    <img
                      class="client-img"
                      src={IMG_URL + feature?.logo}
                      alt=""
                    />
                  </div>
                  <div className="ms-3">
                    <h6 className="title">{feature.name}</h6>
                    <p className="sub-text">{feature.description}</p>
                  </div>
                </div>
              </div>
              </SwiperSlide>
            ))}
              </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyChoose;
